import React from 'react';
import { useTranslation } from 'react-i18next';
function CheckOption(props) {

  const vaccine = props.vaccine;
  const value = props.value;
  const mh = props.mh;
  const updateMedicalHistory = props.updateMedicalHistory;  
  const options = props.options;
  const title = props.title;
  const { t } = useTranslation();

  function isCheked(option){
    if(mh[value]){
      return mh[value].includes(option)
    }
    else{
      return false
    }    
  }

  function checkOption(event) {
  
    var key = event.target.name.split('-')[0]
    var val = event.target.name.split('-')[1]
    var aux = { ...mh };
    if (mh[key]) {
      
      if(aux[key].includes(val)){
        aux[key].pop(val)
        if (aux[key].length === 0) {
          aux[key] = null
        }
      }
      else{
        aux[key].push(val)
      }
      
    }else{
      aux[key] =[val]
      
    }
    updateMedicalHistory(aux);
  }
  return (

    <div className="section-record">
      <div className="title-section">
        {title}
      </div>
      
      <div  className="new-line"> 
        {Object(options).map((option) => {
          return(
            <div className="checkbox-option" key={option}>
              <input
                type="checkbox"
                className={'checkbox-' + vaccine + '-' + option}
                id='checkbox'
                name={value + '-' + option}
                checked={isCheked(option)}
                onChange={checkOption.bind()}
              />
              <label htmlFor="no">{t('medical-history.' + vaccine + '.' + value + '.inputs.'+ option)}</label>
            </div>
          )
        })

      }
    
     </div>

     <style jsx='true'> {`
     #checkbox{
       margin-right: 5px;
     }
      .section-record {
        display: inline-flex !important;
        width: 100%;
      }
      .new-line {
        white-space: pre-wrap;
      }
      .title-section{
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--dark-blue-grey);
        max-width: 40%;
        min-width: 40%;
        margin: 0px;
        margin-bottom: 13px;
      }
      `}
      </style>
      </div>
        
  );
}
export default CheckOption;