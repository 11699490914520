import React from "react";
import SideBar from "../components/LoginSideBar";
import Patients from "../pages/PatientList"
const withLoginLayout = Component => props => {
    var step = props.location.pathname;
    let token = localStorage.getItem("token");
    if (token){
      step = 'three';
    }
    else{
      switch(step) {
        case '/patients':
          step = 'three'
          break;
        case '/code-validation':
          step = 'two'
        break;
        default:
          step = 'one';
      }
    }

    function customComponent(){
      
      step = token ? 'three' : step;
      return token ? <Patients /> :  <Component {...props} /> ;
    }
     
    return (
      <React.Fragment>
        <div className="row"> 
          <div className='sideNav col-3 col-s-12'> <SideBar step={step} /> </div>
          <div className= 'container col-9 col-s-12' > {customComponent()}</div>
        </div>
        <style jsx>{`
          .sideNav {
            height: 55%;
            z-index: 1;
            top: 0;
            left: 0;
            background-color: white;
            overflow-x: 'hidden';
            padding-top: 20px;
          }
          .container {
            background-color: #F3F8F9;
            fontSize: 28px;
            padding: 0px 10px;
          }
        `}</style>
    </React.Fragment>
    );
  }
  
  export default withLoginLayout;