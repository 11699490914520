import React from 'react';
import placeholder from '../assets/images/placeholderapp.svg'
import { useTranslation } from 'react-i18next';
function Apsstore(props) {
  const { t } = useTranslation();
  
  return (
    <React.Fragment>
      
      <div className="col-md-8 col-s-12 offset-md-2">
      
        <img src={placeholder} alt="Logo" className='icon-placeholder'/>
      
        <div className="content col-12 col-s-12">
          <img src="https://assets.website-files.com/5ddd82cc167ced246c60dea3/5ddd88c09f238577c96e7c2f_nbchat-logofull%20(2).png" alt="" className="image" />
          <p className="promo-text">{t('appstore.description')}<br /></p>
        </div>

        
        <div className="content col-12 col-s-12">
          <div className="col-6 col-s-12">
            <a href="https://apps.apple.com/us/app/nimbochat/id1480862199" className="w-inline-block"><img src="https://assets.website-files.com/5ddd82cc167ced246c60dea3/5de03cc2a1f19700276ec388_ios_app_store_btn.png" alt="" className="appstore" /></a>
          </div>
          <div className="col-6 col-s-12">
            <a href="https://play.google.com/store/apps/details?id=com.nimbochat" className="w-inline-block"><img src="https://assets.website-files.com/5ddd82cc167ced246c60dea3/5de03d3e83c9d7c7c0d41f5a_google_play_store_btn.png" alt="" className="appstore" /></a>
          </div>
        </div>

      </div>
     
      <style jsx>{`
        .image {
          max-width: 40%;
        }
        .appstore {
          max-width: 70%;
        }
        .content{
          text-align: center;
        }
      `}</style>
    </React.Fragment>
     
  );
}

export default Apsstore;


