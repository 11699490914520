import React from 'react';
import logoImg from "../assets/images/nimbo-logo.png";
import { useAuth } from "../context/auth";
import { useTranslation } from 'react-i18next';

function LoginSideBar(props) {
  const { t } = useTranslation();
  const step = props.step
  const {authToken} = useAuth();

  function  componentDidMount() {
    var steps = ['one', 'two', 'three'];
    steps = steps.filter(function(item) {
        return item !== step
    })

    var element = document.getElementById(step);
    element.classList.add("currentStep");
    if (authToken){
      for (var x of steps) {
        var old = document.getElementById(x);
        old.classList.add("oldStep");
      }
  
    }
    else if(step === 'two'){
      var old_step = document.getElementById('one');
        old_step.classList.add("oldStep");
    }

  } 
  React.useEffect(() => {
    componentDidMount();
    // eslint-disable-next-line
  }, []);
  return (
    <React.Fragment>
        <div className='steps'>
          <img src={logoImg} alt="Logo"/>
          <hr></hr>
          <ul  className="listSteps">
            <li id="one">{t('login-bar.step_1')}</li>
            <li  id="two">{t('login-bar.step_2')}</li>
            <li id="three">{t('login-bar.step_3')}</li>
          </ul>
        </div>

      <style jsx>{`
        .currentStep{
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          color: var(--deep-sky-blue) !important;

        }
        .currentStep::before{
          color: var(--deep-sky-blue) !important;
          border: solid 1px  var(--deep-sky-blue) !important;
        }
        .currentStep::after {
          content: '➔' !important;
          font-size: 12px;
          width: 2rem;
          height: 2rem;
          margin-top: 9px;
          text-align: right;
          float: right;
          
          
        }
        .oldStep{
          color: var(--light-sky-blue) !important;
        }
        .oldStep::before {
          content: '✓' !important;
          border: solid 1px var(--turquoise-green) !important;
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          display: inline-block;
          line-height: 2rem;
          background-color: var(--turquoise-green) !important;
          text-align: center;
          margin-right: 0.5rem;
          color: white !important;
        }
        img{
          width: 130px;
          margin-bottom: 65px;
        }

        .steps {
          margin-top: 54px;   
          margin-left: 15px;
          margin-right: 15px;         
        }

        hr {
          color: #F4F8F9;
        }

        .listSteps {
          margin-top: 87px;
          list-style: none;
          padding: 0;
          counter-reset: steps;    
        }

      .listSteps li {
        margin-bottom: 26px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        color: var(--steel);
      }

      .listSteps li::before {
        counter-increment: steps; 
        content: counter(steps);
        border: solid 1px  var(--steel);
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        display: inline-block;
        line-height: 2rem;
        color:  var(--steel);
        text-align: center;
        margin-right: 0.5rem;
      }

        `}</style>
    </React.Fragment>
  );
}
export default LoginSideBar;