import React, { useState, useEffect }  from 'react';
import PatientCard from  '../components/PatientCard.js'
import  {getPatientsList} from "../services/Api"
import withLoginLayout from "./../hocs/withLoginLayout";
import { useTranslation } from 'react-i18next';
import Help from '../components/Help';
import Loading from '../components/LoadingIndicator'
function PatientList() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [patients, setPatients] = useState([]);

  async function  componentDidMount() {

    try {
      const response = await getPatientsList();
      if(response.request.status === 200){
        const patients = response.data.people;
        setLoading(false);
        setPatients(patients);

      }
    }catch (e) {
      setLoading(false);
      console.warn("ERROR", e);
      
    }
  }

 useEffect(() => {
   componentDidMount();
 }, []);
  return (
    <div className="forms"> 
      <Help />
      <div className="formBody">
        <h1>{t('patients.choose_medical_record')}</h1>
        <h4>
          {t('patients.instructions_1')}
          <br></br>
          {t('patients.instructions_2')}
        </h4>
        
       
        <ul className="patients-list">
          <Loading working={loading}/>
          { patients.map(patient => <li key={patient.id} ><PatientCard patient= {patient}/></li>)}
        </ul>
      </div>
   
      <style jsx>{`
       .forms {             
          margin-left: 60px;
          margin-right: 30px;
        }
        ul{
          padding-left: 0px;
          width: 50%;
          list-style-type: none;
          margin-top: 44px;
        }
        .formBody{
          margin-top: 4%;
        }

        h1 {
          font-size: 30px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--dark-blue-grey);
        }
        h4 {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: var(--slate);
        }
        `}</style>
      
    </div>
  );
}
export default PatientList;



