import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Check from '../assets/images/green-check.svg';
import  {updateMedicalHistory} from '../services/Api'
import RadioOption from './RadioOption'
import FreeOption from './FreeOption'
import Vaccines from './Vaccines'
function MedicalHistoryTitle(props) {
  const { t } = useTranslation();
  const denied = props.denied
  const data = props.data
  const name = props.name
  const editable = props.editable
  const allowed =['pathological_records','heredofamilial_records','nonpathological_records','vaccines_mx', 'work_history']
  var handle = null
  if(allowed.includes(name) && editable){
    handle = props.onHandle
  }
  return(
    <React.Fragment>
      <h1 className='root-title' onClick={handle}>{t('medical-history.' + name +'.name')}</h1>

      <style jsx='true'>{`
        .root-title{
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--deep-sky-blue);
        }
        .root-title::before {
          content: '+' !important;
          border-radius: 1px;
          width: 10px;
          height: 10px;
          margin-right: 10px
          display: inline-block;
          line-height: 2rem;
          background-color: var(--deep-sky-blue);
          text-align: center;
          padding-left: 5px;
          padding-right: 5px;
          padding-top: 2px;
          padding-bottom: 2px;
          margin-right: 10px;
          color: white !important;
          font-size: 11px;
        }
        `
      }</style>
    </React.Fragment>

  )
}

function MediclaHistoryRecord(props) {
  const name = props.name;
  const values = props.values;
  const id = props.id;
  const editable = props.editable;
  const { t } = useTranslation();
  const key = props.name;
  var [active, setActive] = useState(false);
  var [selected, setSelected] = useState(true);
  var [denied, setDenied] = useState([]);
  var [data, setData] = useState([]);
  var [medicalHistory, setMedicalHistory] = useState(values);

  const inactiveRecordStyle = {
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.1px',
    color: '#344051',
    display: 'grid',
    paddingTop: '30px',
    marginBottom: '20px',
  };

  const activeRecordStyle = {
    paddingTop: '0px',
    marginBottom: '0px',
  };

  async function componentDidMount() {
    renderOptions();
  }
  useEffect(() => {
    componentDidMount();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    renderOptions();
  }, [active]);

  function handleRecord(e) {
    e.preventDefault();
    if(active){
      save()
    }
    setActive(!active);
  }
  function renderOptions() {
    var deny = [];
    var allow = [];

    Object.keys(medicalHistory).forEach(function (item) {
      if (medicalHistory[item] !== null) {
        if (
          medicalHistory[item].active !== undefined &&
          medicalHistory[item].active !== null
        ) {
          if (!medicalHistory[item].active) {
            deny.push(t('medical-history.' + name + '.' + item));
          } else {
            allow.push([
              t('medical-history.' + name + '.' + item),
              medicalHistory[item].text,
            ]);
          }
        } else if (
          Array.isArray(medicalHistory[item]) &&
          medicalHistory[item].length > 0
        ) {
          // como le hago para el join se traduzca?
          var answers = [];
          medicalHistory[item].forEach(function (val) {
            answers.push(
              t('medical-history.' + name + '.' + item + '.inputs.' + val)
            );
          });
          allow.push([
            t('medical-history.' + name + '.' + item + '.name'),
            answers.join(),
          ]);
        }
      }
    });
    setData(allow);
    setDenied(deny);
  }

  function renderDenied() {
    if (denied.length > 0) {
      return (
        <React.Fragment>
          <div className="section-record">
            <div className="title-section">
            {t('medical-history.forms.denied')}
              <span className="uncheck">✕</span>
            </div>
            <div className="body-section-denied">{denied.join()}</div>
          </div>
          <style jsx='true'>{`
            .section-record {
              display: inline-flex !important;
            }
            .uncheck {
              color: purple;
              padding-right: 5px;
              float: right;
              color: red !important;
            }
            .body-section-denied{
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.1px;
              color: #344051;
              max-width: 60%;
              margin-left 8px;
            }
            .section-record {
              display: inline-flex !important;
            }

            .title-section{
              font-size: 12px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #344051;
              max-width: 40%;
              min-width: 40%;
              margin: 0px;
              margin-bottom: 13px;
            }
            `
          }</style>
        </React.Fragment>
      );
    }
  }

  function renderData() {
    return (
      <div className="record-container">
        <div
          responsive="sm"
          style={active ? activeRecordStyle : inactiveRecordStyle}
          className={'recordData-' + name}
          id={'recordData-' + name}
        >
          {renderDenied()}
          {Object.keys(data).map((item, index) => {
            return (
              <React.Fragment key={index}>
                <div className="section-record" key={index}>
                  <div className="title-section">
                    {data[item][0]}
                    <img src={Check} alt="Logo" className="check" />
                  </div>
                  <div className="body-section">{data[item][1]}</div>
                </div>
                <style jsx='true'>{`
                  .body-section{
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.1px;
                    color: #344051;
                    max-width: 60%;
                    margin-left 8px;
                  }
                  .section-record {
                    display: inline-flex !important;
                  }
                  .check {
                    width: 15px;
                    height: 14px;
                    padding-right: 5px;
                    float: right;
                  }
                  .title-section{
                    font-size: 12px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #344051;
                    max-width: 40%;
                    min-width: 40%;
                    margin: 0px;
                    margin-bottom: 13px;
                  }
                `}</style>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  }

  function updateState(value){
    setMedicalHistory(value)
  }

  function save(){
    var json = {medical_history: { }}
    json['medical_history'][name] = medicalHistory
    updateMedicalHistory(json, id)
    setActive(false)
    renderData()
  }
  function checkAll() {
    
    var a = "radio-" + name + '-' 
    var checks = document.querySelectorAll('input[class*='+a+']');
    
    Object(checks).forEach((c) =>{  
       c.checked = selected
    });
    
    Object.keys(medicalHistory).map((item) => {
      if(selected){
        
        if( medicalHistory[item] === null){
          medicalHistory[item] = {active: false, text:null }
        }
       else{
        medicalHistory[item].active = false;
        medicalHistory[item]['text'] = null;
       }
      }

     
     
    })
    

    setSelected(!selected)
    
  }
  function RecordType(record){
    if(record === 'vaccines_mx'){
      return (
        <Vaccines name={name} updateMedicalHistory={updateState} mh={medicalHistory} ></Vaccines>
      )

    }
    else{
      return(
        <React.Fragment>
          <div  responsive="sm" className='action'>
            <span className="denyAll" onClick={checkAll.bind()}> {t('medical-history.forms.deny_all')} </span>
          </div>
          { Object.keys(medicalHistory).map((item) => {
            
            return (
              <div key={item}>
                <RadioOption name={name} item={item} updateMedicalHistory={updateState} mh={medicalHistory}></RadioOption>
              </div>
             
            );
          })
          }

          <style jsx='true'>
            {`
              .denyAll{
                
                transition: 300ms;
                color: var(--deep-sky-blue);
                transition: 300ms;
                text-decoration: underline;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 15px;
                margin-right: 35px;
                font-family: "Proxima Nova", Helvetica, Arial, sans-serif;
                font-size: 14px;
              }
              .action{
                width: 60%;
              }
            `}
          </style>
        </React.Fragment>
      
      )
    }
  }
  function editData() {
    
    return (
      <div className="edit-container">
        <div
          responsive="sm"
          className={'recordData-edit-' + name}
          id={'recordData-' + name}
        > 
          {RecordType(name)}
          {renderSaveBtn()}

        </div>
      </div>
    );
  }
  function renderSaveBtn(){
    return (
      <div  responsive="sm" className='action'>
      <span className='saveInfo' onClick={save.bind()}> {t('medical-history.forms.save')}</span>         
        <style jsx='true'>{`
          .saveInfo{
            color: var(--deep-sky-blue);
            transition: 300ms;
            text-decoration: underline;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 15px;
            margin-right: 35px;
            font-family: "Proxima Nova", Helvetica, Arial, sans-serif;
            font-size: 14px;
          }
          .action{
            width: 60%;
          }
        `}</style>
      </div>
    )
  }

  function selectActive() {
    

    if(editable){
      if (active) {
        return editData();
      } else {
        return renderData();
      }
    }
    else{
      return renderData();
    }
  }

  return (
    <div className="medical-record" key={key}>
      <MedicalHistoryTitle data={data} denied={denied} name={name} onHandle={handleRecord} editable={editable}></MedicalHistoryTitle>

      {selectActive()}
      <style jsx='true'>{`
       .medical-record .row-data{
        word-break: break-all;
        
       }  
        .medical-record ul{
          padding-left: 0px;
          width: 50%;
          list-style-type: none;
          margin-top: 44px;
        }
     
        `}</style>
    </div>
  );
}
export default MediclaHistoryRecord;
