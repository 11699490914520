import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';


const LanguageDropdown = () => {
  const { i18n, t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const languages = {
    en: { name: t('common.languages.en'), flag: '🇺🇸' },
    es: { name: t('common.languages.es'), flag: '🇲🇽' },
  };

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
    localStorage.setItem('selectedLanguage', lng);
  };

  useEffect(() => {
    setSelectedLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <Dropdown>
      <Dropdown.Toggle variant="light" id="dropdown-flag">
        <span className='text-selected-language'>{ selectedLanguage }</span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {Object.keys(languages).map((lng) => (
          <Dropdown.Item
            key={lng}
            onClick={() => handleLanguageChange(lng)}
            active={selectedLanguage === lng}
          >
            {languages[lng].name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
      <style jsx='true'>{`
        .text-selected-language {
          text-transform: uppercase;
        }
      `}</style>
    </Dropdown>
  );
};

export default LanguageDropdown;
