import i18n from 'i18next';
import {initReactI18next } from 'react-i18next';

const browserLanguage = navigator.language.split('-')[0];

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          'appstore':{
            'description': "You're one step closer from a better relationship with your Doctor."
          },
          'consultations':{
            'title': 'My past encounters',
            'see-more': 'See More'
          },
          'patients': {
            'choose_medical_record': 'Choose your Medical Record',
            'no-medical-record-found': 'No medical records were found',
            'instructions_1': 'We found these medical records related with that phone number',
            'instructions_2': 'Please select the one you want to access',
          } ,
          'login-bar':{
            'step_1': 'ENTER YOUR MOBILE NUMBER',
            'step_2': 'VERIFICATION CODE',
            'step_3': 'CHOOSE YOUR MEDICAL RECORD',
          },
          'validation-form': {
            'title': 'Verification Code',
            'description': 'Please enter the code sent to '
          },
          'login-form':{
            'title': 'Welcome Patient',
            'description': 'Please enter with your phone number to continue',
            'country': 'Country',
            'mobile': 'Mobile number',
            'having_trouble': 'Having trouble?',
            'get_help': 'Get Help',
            'digits-placeholder': '10 digits',
            'next': 'Next',
            'country-code-placeholder': 'Search country'
          },
          'medical-history': {
            'allergies': 'ALLERGIES',
            'active-allergies': 'Active allergies',
            'forms': {
              'save': 'Save',
              'deny_all': 'Deny all',
              'yes': 'Yes',
              'no': 'No',
              'denied': 'Denied'
            },
            'other-allergies': 'Other allergies',
            'history': 'History',
            'my-medical-history': 'My Medical History' ,
            'my-vital-signs': 'My Vital Signs',
            'my-active-medications': 'My Active Medications',
            'my-past-encounters': 'My Past Encounters',
            'no-to-all': 'No to all',
            'denied-record': 'n/a',
            'pathological_records': {
              'prior_hospitalization': 'Prior Hospitalization',
              'prior_surgeries': 'Prior Surgeries',
              'diabetes': 'Diabetes',
              'thyroid_diseases': 'Thyroid Diseases',
              'hypertension': 'Hypertension',
              'heart_diseases': 'Heart Diseases',
              'injuries': 'Injuries',
              'name': 'PATHOLOGICAL HISTORY',
              'cancer': 'Cancer',
              'tuberculosis': 'Tuberculosis',
              'transfusions': 'Transfusions',
              'respiratory': 'Respiratory pathologies',
              'gastrointestinal': 'Gastrointestinal pathologies',
              'stds': 'Sexually transmitted diseases',
              'other': 'Other'
            },
            'nonpathological_records': {
              'physical_activity': 'Physical Activity',
              'smoking': 'Smoking',
              'alcoholism': 'Alcohol Intake',
              'drugs_abuse': 'Drug Abuse',
              'recent_immunization': 'Recent Immunization',
              'other': 'Other',
              'name': 'NONPATHOLOGICAL RECORDS'
            },
            'perinatal_records': {
              'last_menstrual_period': 'Last menstrual period',
              'cycle_length': 'Cycle length',
              'last_used_contraceptive_type': 'Last used contraceptive type',
              'assisted_conception': 'Assisted conception',
              'estimated_date_of_birth_by_lmp': 'Estimated date of birth by LMP',
              'final_edb': 'Final EDB',
              'pregnancy_notes': 'Pregnancy notes',
              'name': 'PERINATAL HISTORY'
            },
            'postnatal_records': {
              'birth_review': 'Birth review',
              'name_of_baby': 'Name of baby',
              'weight_at_birth': 'Weight at birth',
              'babys_health': 'Baby’s health',
              'infant_feeding': {
                'name': 'Infant feeding',
                'inputs': {
                  'breast_milk': 'Breast milk',
                  'breast_milk_substitute': 'Breast milk substitute',
                  'breast_milk_and_breast_milk_substitute': 'Breast milk and breast milk substitute'
                }
              },
              'emotional_state': 'Emotional State',
              'name': 'POSTNATAL HISTORY'
            },
            'psychiatric_records': {
              'family_history': 'Family History',
              'illness_awareness': 'Awareness of illness',
              'affected_life_aspects': 'Aspect of life affected by illness',
              'past_and_present_treatments': 'Past and present treatments',
              'family_and_friends_support': 'Support from family and friends',
              'family_life': 'Family life',
              'social_life': 'Social life',
              'work_life': 'Work life',
              'relationship_with_authority': 'Relationship with authority',
              'impulse_control': 'Impulse control',
              'deals_with_stress': 'Deals with stress',
              'name': 'PSYCHIATRIC HISTORY'
            },
            'gineco_obstetrics_records': {
              'menstruation_first_date': 'Menstruation First Date',
              'menstruation_last_date': 'Menstruation Last Date',
              'menstruation_characteristics': 'Menstruation Characteristics',
              'pregnancies': 'Pregnancies',
              'cervical_cancer': 'Cervical Cancer',
              'uterine_cancer': 'Uterine Cancer',
              'breast_cancer': 'Breast Cancer',
              'sexual_activity': 'Sexual Activity',
              'birth_control_method': 'Birth control methods used',
              'hormone_replacement_therapy': 'Hormone replacement therapy',
              'last_pap_smear': 'Last Pap smear',
              'last_mastography': 'Last Mastography',
              'other': 'Other',
              'name': 'OBSTETRIC AND GYNECOLOGICAL HISTORY'
            },
            'heredofamilial_records': {
              'diabetes': 'Diabetes',
              'heart_diseases': 'Heart Diseases',
              'hypertension': 'Hypertension',
              'thyroid_diseases': 'Thyroid Diseases',
              'other': 'Other',
              'name': 'FAMILY HISTORY'
            },
            'vaccines_mx': {
              'name': 'VACCINES (MEXICO)',
              'other': 'Other',
              'newborn': {
                'name': 'Newborn',
                'inputs': {
                  'bcg': 'BCG',
                  'hep_b': '1st Hepatitis B'
                }
              },
              'other': 'other',
              'month_2': {
                'name': '2 months',
                'inputs': {
                  'penta': '1st Pentavalente Acelular',
                  'hep_b': '2nd Hepatitis B',
                  'rotavirus': '1st Rotavirus',
                  'neumo': '1st Neumococo'
                }
              },
              'month_4': {
                'name': '4 months',
                'inputs': {
                  'penta': '2nd Pentavalente Acelular',
                  'rotavirus': '2nd Rotavirus',
                  'neumo': '2nd Neumococo'
                }
              },
              'month_6': {
                'name': '6 months',
                'inputs': {
                  'penta': '3rd Pentavalente Acelular',
                  'hep_b': '3rd Hepatitis B',
                  'rotavirus': '3rd Rotavirus',
                  'flu': '1st Anti Flu (winter)'
                }
              },
              'month_7': {
                'name': '7 months',
                'inputs': {
                  'flu': '2nd Anti Flu (winter)'
                }
              },
              'month_12': {
                'name': '12 months',
                'inputs': {
                  'srp': '1st SRP',
                  'neumo': '3rd Neumococo'
                }
              },
              'month_18': {
                'name': '18 months',
                'inputs': {
                  'penta': '4th Pentavalente Acelular'
                }
              },
              'month_24': {
                'name': '2 years',
                'inputs': {
                  'flu': 'Flu Yearly (oct-jan)'
                }
              },
              'month_36': {
                'name': '3 years',
                'inputs': {
                  'flu': 'Flu Yearly (oct-jan)'
                }
              },
              'month_48': {
                'name': '4 years',
                'inputs': {
                  'dpt': 'DPT',
                  'flu': 'Flu Yearly (oct-jan)'
                }
              },
              'month_60': {
                'name': '5 years',
                'inputs': {
                  'flu': 'Flu Yearly (oct-jan)',
                  'vop': 'VOP/OPV'
                }
              },
              'month_72': {
                'name': '6 years',
                'inputs': {
                  'srp': '2nd SRP'
                }
              },
              'month_120': {
                'name': '11 years',
                'inputs': {
                  'vph': 'VPH'
                }
              }
            },
            'diet': {
              'name': 'DIET',
              'appetite_level': {
                'name': 'Appetite Level',
                'inputs': {
                  'excesive': 'Excesive',
                  'good': 'Good',
                  'fair': 'Fair',
                  'poor': 'Poor',
                  'none': 'None'
                }
              },
              'breakfast': 'Breakfast',
              'collation_morning': 'Collation Morning',
              'lunch': 'Lunch',
              'collation_afternoon': 'Collation Afternoon',
              'dinner': 'Dinner',
              'home_prepared': 'Prepared at home',
              'satiety': 'Satiety',
              'glasses_water_day': {
                'name': 'Number of water glasses per day',
                'inputs': {
                  'one_or_less': '1 or less',
                  'two_to_three': '2 to 3',
                  'four_or_more': '4 or more'
                }
              },
              'food_preferences': 'Food preferences',
              'food_diseases': 'Food diseases',
              'supplements': 'Supplements',
              'past_diets': 'Past diets',
              'ideal_weight': 'Ideal weight',
              'current_ailment': 'Current aliments related to weight',
              'past_ailment': 'Past ailments related to weight',
              'liquid_intake': 'Intake of liquids',
              'nutritional_education': 'Nutritional education',
              'other': 'Others'
            },

            'work_history': {
              'past_employment': 'Past employment',
              'accident_caused_disabilities': 'Accident caused disabilities',
              'sickness_caused_disabilities': 'Sickness caused disabilities',
              'contact_with_dust_particles': 'Contact with dust particles',
              'exposure_to_loud_noises': 'Exposure to loud noise',
              'exposure_to_chemicals': 'Skin conditions due to exposure to chemicals',
              'fractures_or_injuries': 'Work related fractures or injuries',
              'accidents_or_illnesses': 'Accidents or illnesses due to work',
              'fit_to_work': 'Fit to work',
              'others': 'Others',
              'name': 'WORK HISTORY'
            }
          },
          'attachments':{
            'title': 'My Attachments'
          },
          'prescriptions': {
            'title': 'My Prescriptions',
            'dose': 'Dose: ',
            'frequency': 'Frequency: ',
            'length': 'Length: ',
            'name': 'Name',
            'prescribed': 'Prescribed by',
            'date_prescribed': 'Date Prescribed',
            'instrucctions': 'Instructions:'

          },
          'lab-test-result': {
            'units-title': {
              'copies_ml': 'Copies/mL',
              'detected': 'Detected',
              'not_detected': 'Not detected',
              'reactive': 'Reactive',
              'non_reactive': 'Non reactive',
              'not_done': 'Not done'
            },
            'title': 'Lab Test Results',
            'not-enabled-1': 'All lab test results are disabled.',
            'not-enabled-2': 'Enable them here.',
            'basic_metabolic_panel': {
              'title': 'Basic metabolic panel',
              'elements': {
                'blood_urea_nitrogen': 'Blood urea nitrogen',
                'calcium': 'Calcium',
                'co2': 'CO2',
                'chloride': 'Chloride',
                'creatinine': 'Creatinine',
                'glucose': 'Glucose',
                'potassium': 'Potassium',
                'sodium': 'Sodium'
              }
            },
            'complete_blood_count': {
              'title': 'Complete blood count',
              'elements': {
                'hematocrit': 'Hematocrit',
                'hemoglobin': 'Hemoglobin',
                'platelet': 'Platelet',
                'red_blood_cells': 'Red blood cells',
                'reticulocyte': 'Reticulocyte',
                'white_blood_cell': 'White blood cell'
              }
            },
            'lipid_profile': {
              'title': 'Lipid profile',
              'elements': {
                'low_density_lipoprotein': 'Low-density lipoprotein',
                'high_density_lipoprotein': 'High-density lipoprotein',
                'total_cholesterol': 'Total cholesterol',
                'triglycerides': 'Triglycerides',
                'very_low_density_lipoprotein': 'Very low-density lipoprotein'
              }
            },
            'sti_screening_test': {
              'title': 'STI Screening Test',
              'elements': {
                'hiv_gen3': 'HIV Gen3',
                'hiv_gen4': 'HIV Gen4',
                'hepa_igg_igm': 'HepA IgG/IgM',
                'hepb_as': 'HepB AS',
                'hepb_coreab': 'HepB CoreAb',
                'syphilis_ab': 'Syphilis Ab',
                'h_pylori_ab': 'H. Pylori Ab',
                'gonorrhea_ab': 'Gonorrhea Ab',
                'chlamydia_ab': 'Chlamydia Ab',
                'vdrl_qty': 'VDRL Qty',
                'vdrl_qlty': 'VDRL Qlty',
                'elisa_gen3': 'Elisa Gen3',
                'elisa_gen4': 'Elisa Gen4'
              }
            },
            'molecular_biology': {
              'title': 'Molecular biology',
              'elements': {
                'hiv_viral_load': 'HIV Viral Load',
                'tb_detection': 'TB Detection',
                'tb_rif_resistance': 'TB Rif Resistance',
                'hepb_viral_load': 'HepB Viral Load',
                'hepc_viral_load': 'HepC Viral Load',
                'hpv_pcr': 'HPV PCR'
              }
            },
            'diabetes_prediabetes': {
              'title': 'Diabetes / Prediabetes',
              'elements': {
                'glycosylated_hemoglobin': 'Glycosylated hemoglobin'
              }
            }
          },

          'profile':{
            'sidebar': {
              'my-health': 'My Health',
              'appointments': 'Appointmets',
              'messages': 'Messages'
            },
            'sections':{
              'my-health': 'My Health'
            }
          },
          'vital-signs':{
            'title': 'Vital Signs',
            'height': 'Height',
            'weight': 'Weight',
            'temperature': 'Temperature',
            'respiratory_rate': 'Respiratory Rate',
            'systole': 'Systole',
            'diastole': 'Diastole',
            'heart_rate': 'Heart Rate',
            'body_mass': 'Body Mass',
            'body_fat_percentage': 'Body Fat Percentage',
            'lean_body_mass': 'Lean Body Mass',
            'head_circumference': 'Head Circumference',
            'oxygen_saturation': 'Oxygen Saturation'
          },
          'appointments': {
            'appointment': "Appointment",
            'telehealth': "Telehealth",
            'title-requests': 'Appointment requests',
            'title-scheduled': 'Scheduled appointments',
            'organization-portals': 'Schedule an appointment from the portals',
            "upcoming-appointments": "Upcoming Appointments",
            "schedule-appointment": "Schedule an appointment",
            "my-physicians": "My physicians",
            "physicians-directory": "physician directory",
            "clinic-schedule": "Clinic schedule",
            'status': {
              'scheduled': 'Scheduled',
              'confirmed': 'Confirmed',
              'pending_cancellation': 'Pending cancellation',
              'cancellation_request_accepted': 'Cancellation rejected (scheduled)',
              'cancellation_request_rejected': 'Cancellation rejected (scheduled)',
              'arrived': 'arrived',
              'started': 'Started',
              'pending': 'Pending',
            },
            'actions': {
              'confirm': {
                'title': 'Confirm',
                'modal': {
                  'title': 'Confirm appointment',
                  'message': 'Are you sure to confirm this appointment?',
                  'close': 'Close',
                  'accept': 'Confirm'
                },
                'success': 'Appointment confirmed successfully!',
                'error': 'An error occurred while confirming the appointment!'
              },
              'cancel': {
                'title': 'Cancel',
                'modal': {
                  'title': 'Cancel appointment',
                  'message': 'Are you sure to request the cancellation of this appointment?',
                  'close': 'Close',
                  'accept': 'Confirm'
                },
                'success': 'The cancellation request has been made successfully!',
                'error': 'An error occurred while creating the cancellation request!'
              },
              'close': 'Close'
            }
          },
          'common': {
            'loading': 'Loading...',
            'continue': 'Continue',
            'cancel': 'Cancel',
            'next': 'Next',
            'no-data': 'No data',
            'change-patient': 'Change patient',
            'log-in': 'Log In',
            'log-out': 'Log Out',
            'languages': {
              'en': 'English',
              'es': 'Spanish',
            }
          }
        }
      },
      es: {
        "translation": {
          "appstore": {
            "description": "Estás a un paso de mejorar tu relación con tu médico."
          },
          "consultations": {
            "title": "Mis encuentros pasados",
            "see-more": "Ver más"
          },
          "patients": {
            "choose_medical_record": "Elige tu expediente médico",
            "no-medical-record-found": "No se encontraron expedientes médicos",
            "instructions_1": "Hemos encontrado estos expedientes médicos relacionados con ese número de teléfono",
            "instructions_2": "Por favor, selecciona el que deseas acceder"
          },
          "login-bar": {
            "step_1": "INGRESA TU NÚMERO CELULAR",
            "step_2": "RECIBE EL CÓDIGO DE VERIFICACIÓN",
            "step_3": "ELIGE TU EXPEDIENTE MÉDICO"
          },
          "validation-form": {
            "title": "Ingresa tu codigo de verificacion",
            "description": "Revisa tu whatsapp desde tu celular. Recibiras un codigo de Nimbo by Ecaresoft. No compartas este codigo con nadie. El mensaje se envio a: "
          },
          "login-form": {
            "title": "Accede al portal para pacientes",
            "description": "Ingresa tu número de celular y recibe un código por WhatsApp para acceder a tu expediente de manera segura.",
            "country": "País",
            "mobile": "Número de móvil",
            "having_trouble": "¿Tienes problemas?",
            "get_help": "Obtener ayuda",
            "digits-placeholder": "10 dígitos",
            "next": "Siguiente",
            'country-code-placeholder': 'Buscar país'
          },
          "medical-history": {
            "allergies": "ALERGIAS",
            "active-allergies": "Alergias conocidas",
            "forms": {
              "save": "Guardar",
              "deny_all": "Negar todo",
              "yes": "Sí",
              "no": "No",
              "denied": "Negado"
            },
            "other-allergies": "Otras alergias",
            "history": "Antecedentes",
            "my-medical-history": "Mi antecedentes clinicos",
            "my-vital-signs": "Mis signos vitales",
            "my-active-medications": "Mis medicamentos activos",
            "my-past-encounters": "Mis encuentros pasados",
            "no-to-all": "No a todo",
            "denied-record": "n/d",
            "pathological_records": {
              "prior_hospitalization": "Hospitalizaciones previas",
              "prior_surgeries": "Cirugías previas",
              "diabetes": "Diabetes",
              "thyroid_diseases": "Enfermedades tiroideas",
              "hypertension": "Hipertensión",
              "heart_diseases": "Enfermedades cardíacas",
              "injuries": "Lesiones",
              "name": "ANTECEDENTES PATOLÓGICOS",
              "cancer": "Cáncer",
              "tuberculosis": "Tuberculosis",
              "transfusions": "Transfusiones",
              "respiratory": "Patologías respiratorias",
              "gastrointestinal": "Patologías gastrointestinales",
              "stds": "Enfermedades de transmisión sexual",
              "other": "Otro"
            },
            "nonpathological_records": {
              "physical_activity": "Actividad física",
              "smoking": "Uso de tabaco o productos de nicotina",
              "alcoholism": "Consumo de alcohol",
              "drugs_abuse": "Uso de drogas",
              "recent_immunization": "Inmunización reciente",
              "other": "Otro",
              "name": "ANTECEDENTES NO PATOLÓGICOS"
            },
            "perinatal_records": {
              "last_menstrual_period": "Última menstruación",
              "cycle_length": "Duración del ciclo",
              "last_used_contraceptive_type": "Último tipo de anticonceptivo usado",
              "assisted_conception": "Concepción asistida",
              "estimated_date_of_birth_by_lmp": "Fecha estimada de parto por FUM",
              "final_edb": "Fecha final estimada de parto",
              "pregnancy_notes": "Notas del embarazo",
              "name": "ANTECEDENTES PERINATALES"
            },
            "postnatal_records": {
              "birth_review": "Revisión del nacimiento",
              "name_of_baby": "Nombre del bebé",
              "weight_at_birth": "Peso al nacer",
              "babys_health": "Salud del bebé",
              "infant_feeding": {
                "name": "Alimentación infantil",
                "inputs": {
                  "breast_milk": "Leche materna",
                  "breast_milk_substitute": "Sustituto de leche materna",
                  "breast_milk_and_breast_milk_substitute": "Leche materna y sustituto de leche materna"
                }
              },
              "emotional_state": "Estado emocional",
              "name": "ANTECEDENTES POSTNATALES"
            },
            "psychiatric_records": {
              "family_history": "Historial familiar",
              "illness_awareness": "Consciencia de la enfermedad",
              "affected_life_aspects": "Aspectos de la vida afectados por la enfermedad",
              "past_and_present_treatments": "Tratamientos pasados y presentes",
              "family_and_friends_support": "Apoyo de familiares y amigos",
              "family_life": "Vida familiar",
              "social_life": "Vida social",
              "work_life": "Vida laboral",
              "relationship_with_authority": "Relación con la autoridad",
              "impulse_control": "Control de impulsos",
              "deals_with_stress": "Manejo del estrés",
              "name": "ANTECEDENTES PSIQUIÁTRICOS"
            },
            "gineco_obstetrics_records": {
              "menstruation_first_date": "Fecha de la primera menstruación",
              "menstruation_last_date": "Fecha de la última menstruación",
              "menstruation_characteristics": "Características de la menstruación",
              "pregnancies": "Embarazos",
              "cervical_cancer": "Cáncer de cuello uterino",
              "uterine_cancer": "Cáncer uterino",
              "breast_cancer": "Cáncer de mama",
              "sexual_activity": "Actividad sexual",
              "birth_control_method": "Métodos anticonceptivos utilizados",
              "hormone_replacement_therapy": "Terapia de reemplazo hormonal",
              "last_pap_smear": "Última citología",
              "last_mastography": "Última mastografía",
              "other": "Otro",
              "name": "ANTECEDENTES GINECO-OBSTÉTRICOS"
            },
            "heredofamilial_records": {
              "diabetes": "Diabetes",
              "heart_diseases": "Enfermedades cardíacas",
              "hypertension": "Hipertensión",
              "thyroid_diseases": "Enfermedades tiroideas",
              "other": "Otro",
              "name": "ANTECEDENTES HEREDOFAMILIARES"
            },
            "vaccines_mx": {
              "name": "VACUNAS",
              "other": "Otro",
              "newborn": {
                "name": "Recién nacido",
                "inputs": {
                  "bcg": "BCG",
                  "hep_b": "1ra Hepatitis B"
                }
              },
              "month_2": {
                "name": "2 meses",
                "inputs": {
                  "penta": "1ra Pentavalente Acelular",
                  "hep_b": "2da Hepatitis B",
                  "rotavirus": "1ra Rotavirus",
                  "neumo": "1ra Neumococo"
                }
              },
              "month_4": {
                "name": "4 meses",
                "inputs": {
                  "penta": "2da Pentavalente Acelular",
                  "rotavirus": "2da Rotavirus",
                  "neumo": "2da Neumococo"
                }
              },
              "month_6": {
                "name": "6 meses",
                "inputs": {
                  "penta": "3ra Pentavalente Acelular",
                  "hep_b": "3ra Hepatitis B",
                  "rotavirus": "3ra Rotavirus",
                  "flu": "1ra Antiinfluenza (invierno)"
                }
              },
              "month_7": {
                "name": "7 meses",
                "inputs": {
                  "flu": "2da Antiinfluenza (invierno)"
                }
              },
              "month_12": {
                "name": "12 meses",
                "inputs": {
                  "srp": "1ra SRP",
                  "neumo": "3ra Neumococo"
                }
              },
              "month_18": {
                "name": "18 meses",
                "inputs": {
                  "penta": "4ta Pentavalente Acelular"
                }
              },
              "month_24": {
                "name": "2 años",
                "inputs": {
                  "flu": "Anual (oct-ene)"
                }
              },
              "month_36": {
                "name": "3 años",
                "inputs": {
                  "flu": "Anual (oct-ene)"
                }
              },
              "month_48": {
                "name": "4 años",
                "inputs": {
                  "dpt": "DPT",
                  "flu": "Anual (oct-ene)"
                }
              },
              "month_60": {
                "name": "5 años",
                "inputs": {
                  "flu": "Anual (oct-ene)",
                  "vop": "VOP/OPV"
                }
              },
              "month_72": {
                "name": "6 años",
                "inputs": {
                  "srp": "2da SRP"
                }
              },
              "month_120": {
                "name": "11 años",
                "inputs": {
                  "vph": "VPH"
                }
              }
            },
            "diet": {
              "name": "DIETA",
              "appetite_level": {
                "name": "Nivel de apetito",
                "inputs": {
                  "excesive": "Excesivo",
                  "good": "Bueno",
                  "fair": "Regular",
                  "poor": "Bajo",
                  "none": "Ninguno"
                }
              },
              "breakfast": "Desayuno",
              "collation_morning": "Colación matutina",
              "lunch": "Comida",
              "collation_afternoon": "Colación vespertina",
              "dinner": "Cena",
              "home_prepared": "Preparado en casa",
              "satiety": "Satisfacción",
              "glasses_water_day": {
                "name": "Número de vasos de agua al día",
                "inputs": {
                  "one_or_less": "1 o menos",
                  "two_to_three": "2 a 3",
                  "four_or_more": "4 o más"
                }
              },
              "food_preferences": "Preferencias alimenticias",
              "food_diseases": "Enfermedades alimenticias",
              "supplements": "Suplementos",
              "past_diets": "Dietas pasadas",
              "ideal_weight": "Peso ideal",
              "current_ailment": "Afecciones actuales relacionadas con el peso",
              "past_ailment": "Afecciones pasadas relacionadas con el peso",
              "liquid_intake": "Ingesta de líquidos",
              "nutritional_education": "Educación nutricional",
              "other": "Otros"
            },
            "work_history": {
              "past_employment": "Empleos anteriores",
              "accident_caused_disabilities": "Accidentes causaron discapacidades",
              "sickness_caused_disabilities": "Enfermedades causaron discapacidades",
              "contact_with_dust_particles": "Contacto con partículas de polvo",
              "exposure_to_loud_noises": "Exposición a ruidos fuertes",
              "exposure_to_chemicals": "Problemas de piel por exposición a químicos",
              "fractures_or_injuries": "Fracturas o lesiones relacionadas con el trabajo",
              "accidents_or_illnesses": "Accidentes o enfermedades relacionadas con el trabajo",
              "fit_to_work": "Apto para trabajar",
              "others": "Otros",
              "name": "HISTORIAL LABORAL"
            }
          },
          "attachments": {
            "title": "Mis archivos adjuntos"
          },
          "prescriptions": {
            "title": "Mis recetas",
            "dose": "Dosis: ",
            "frequency": "Frecuencia: ",
            "length": "Duración: ",
            "name": "Nombre",
            "prescribed": "Prescrito por",
            "date_prescribed": "Fecha de prescripción",
            "instrucctions": "Instrucciones:"
          },
          "lab-test-result": {
            "units-title": {
              "copies_ml": "Copias/mL",
              "detected": "Detectado",
              "not_detected": "No detectado",
              "reactive": "Reactivo",
              "non_reactive": "No reactivo",
              "not_done": "No realizado"
            },
            "title": "Resultados de pruebas de laboratorio",
            "not-enabled-1": "Todos los resultados de las pruebas de laboratorio están desactivados.",
            "not-enabled-2": "Habilítalos aquí.",
            "basic_metabolic_panel": {
              "title": "Panel metabólico básico",
              "elements": {
                "blood_urea_nitrogen": "Nitrógeno ureico en sangre",
                "calcium": "Calcio",
                "co2": "CO2",
                "chloride": "Cloruro",
                "creatinine": "Creatinina",
                "glucose": "Glucosa",
                "potassium": "Potasio",
                "sodium": "Sodio"
              }
            },
            "complete_blood_count": {
              "title": "Recuento sanguíneo completo",
              "elements": {
                "hematocrit": "Hematocrito",
                "hemoglobin": "Hemoglobina",
                "platelet": "Plaquetas",
                "red_blood_cells": "Glóbulos rojos",
                "reticulocyte": "Reticulocito",
                "white_blood_cell": "Glóbulos blancos"
              }
            },
            "lipid_profile": {
              "title": "Perfil lipídico",
              "elements": {
                "low_density_lipoprotein": "Lipoproteína de baja densidad",
                "high_density_lipoprotein": "Lipoproteína de alta densidad",
                "total_cholesterol": "Colesterol total",
                "triglycerides": "Triglicéridos",
                "very_low_density_lipoprotein": "Lipoproteína de muy baja densidad"
              }
            },
            "sti_screening_test": {
              "title": "Prueba de detección de ITS",
              "elements": {
                "hiv_gen3": "HIV Gen3",
                "hiv_gen4": "HIV Gen4",
                "hepa_igg_igm": "HepA IgG/IgM",
                "hepb_as": "HepB AS",
                "hepb_coreab": "HepB CoreAb",
                "syphilis_ab": "Sífilis Ab",
                "h_pylori_ab": "H. Pylori Ab",
                "gonorrhea_ab": "Gonorrea Ab",
                "chlamydia_ab": "Chlamydia Ab",
                "vdrl_qty": "VDRL Cantidad",
                "vdrl_qlty": "VDRL Cualitativa",
                "elisa_gen3": "Elisa Gen3",
                "elisa_gen4": "Elisa Gen4"
              }
            },
            "molecular_biology": {
              "title": "Biología molecular",
              "elements": {
                "hiv_viral_load": "Carga viral del VIH",
                "tb_detection": "Detección de TB",
                "tb_rif_resistance": "Resistencia a Rif de TB",
                "hepb_viral_load": "Carga viral de HepB",
                "hepc_viral_load": "Carga viral de HepC",
                "hpv_pcr": "PCR del VPH"
              }
            },
            "diabetes_prediabetes": {
              "title": "Diabetes / Prediabetes",
              "elements": {
                "glycosylated_hemoglobin": "Hemoglobina glicosilada"
              }
            }
          },
          "profile": {
            "sidebar": {
              "my-health": "Mi salud",
              "appointments": "Citas",
              "messages": "Mensajes"
            },
            "sections": {
              "my-health": "Mi salud"
            }
          },
          "vital-signs": {
            "title": "Signos vitales",
            "height": "Altura",
            "weight": "Peso",
            "temperature": "Temperatura",
            "respiratory_rate": "Frecuencia respiratoria",
            "systole": "Sistolica",
            "diastole": "Diastolica",
            "heart_rate": "Frecuencia cardíaca",
            "body_mass": "Masa corporal",
            "body_fat_percentage": "Porcentaje de grasa corporal",
            "lean_body_mass": "Masa corporal magra",
            "head_circumference": "Circunferencia de la cabeza",
            "oxygen_saturation": "Saturación de oxígeno"
          },
          "appointments": {
            "appointment": "Cita",
            "telehealth": "Telemedicina",
            "title-requests": "Solicitudes de citas",
            "title-scheduled": "Citas programadas",
            "organization-portals": "Programa una cita desde los portales",
            "upcoming-appointments": "Próximas citas",
            "schedule-appointment": "Programar una cita",
            "my-physicians": "Médicos con los que has consultado:",
            "physicians-directory": "directorio de médicos",
            "clinic-schedule": "Horario de la clínica",
            "status": {
              "scheduled": "Programado",
              "confirmed": "Confirmado",
              "pending_cancellation": "Cancelación pendiente",
              "cancellation_request_accepted": "Cancelación rechazada (programada)",
              "cancellation_request_rejected": "Cancelación rechazada (programada)",
              "arrived": "llegado",
              "started": "Iniciado",
              "pending": "Pendiente"
            },
            "actions": {
              "confirm": {
                "title": "Confirmar",
                "modal": {
                  "title": "Confirmar cita",
                  "message": "¿Estás seguro de confirmar esta cita?",
                  "close": "Cerrar",
                  "accept": "Confirmar"
                },
                "success": "¡Cita confirmada con éxito!",
                "error": "¡Ocurrió un error al confirmar la cita!"
              },
              "cancel": {
                "title": "Cancelar",
                "modal": {
                  "title": "Cancelar cita",
                  "message": "¿Estás seguro de solicitar la cancelación de esta cita?",
                  "close": "Cerrar",
                  "accept": "Confirmar"
                },
                "success": "¡Solicitud de cancelación realizada con éxito!",
                "error": "¡Ocurrió un error al solicitar la cancelación!"
              },
              "close": "Cerrar"
            }
          },
          "common": {
            "loading": "Cargando...",
            "continue": "Continuar",
            "cancel": "Cancelar",
            "next": "Siguiente",
            "no-data": "Sin datos",
            "change-patient": "Cambiar paciente",
            "log-in": "Iniciar sesión",
            "log-out": "Cerrar sesión",
            'languages': {
              'en': 'Ingles',
              'es': 'Español',
            }
          }
        }
      }
    },
    lng: localStorage.getItem('selectedLanguage') || browserLanguage || 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
