import React, { useState } from "react";
import  {getRequestCode} from "../services/Api"
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useLocalStorage from './../hook/useLocalStorage';
import Countries from './CountryCodeSelect';
import Help from './Help'
import Loading from '../components/LoadingIndicator'
function LoginForm() {
  const { t } = useTranslation();
  const [countryCode, setCountryCode] = useLocalStorage("COUNTRY_CODE_KEY", "52");
  const [phone, setPhone] = useLocalStorage("PHONE_KEY", "");
  const [loading, setLoading] = useState(false);

  let history = useHistory();

  async function handleSubmit(e){
    e.preventDefault();
    setLoading(true)
    const {countryCode, nationalNumber } = e.target.elements;

    const data = {
      country_code: (countryCode.value === '') ? '52' : countryCode.value,
      phone: nationalNumber.value
    };

    const response = await getRequestCode(data);
    if(response.request.status === 200){
      setLoading(false)
      history.push('/code-validation');
    }else{
      setLoading(false)
      history.push('/no-user');
    }

  }

  async function handleUpdateCountry(_country){
    var res = _country.split("-");
    setCountryCode(res[1]);
  }

  return (
    <React.Fragment>
      <div className="forms">
        <Help />

        <div className="formBody">

          <h1>{t('login-form.title')}</h1>
          <h4>{t('login-form.description')}</h4>
          <form className='formBox' onSubmit={handleSubmit}>
            <label>{t('login-form.country')}</label>
            <Countries onUpdateCountry={handleUpdateCountry} />
            <input
              name="countryCode"
              type="hidden"
              placeholder="code"
              onChange={e => {
                setCountryCode(e.target.value);
              }}
              value={countryCode}
              id='codeCountry'
            />

            <label>{t('login-form.mobile')}</label>
            <input
              name="nationalNumber"
              type="text"
              placeholder={t('login-form.digits-placeholder')}
              onChange={e => {
                setPhone(e.target.value);
              }}
              id='phoneTxt'
              required='required'
            />
            <Loading working={loading}/>
            <div className='actions'>
              <button className='sendBtn' type="submit">{t('login-form.next')}</button>
            </div>

          </form>
          </div>
      </div>
      <style jsx>{`

        .actions{
          margin-top: 152px;
        }
        input {
          margin-top: 14px;
        }
        label {
          color: #707070;
          margin-bottom: 5px;
        }
        h1 {
          font-size: 30px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--dark-blue-grey);
        }
        h4 {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: var(--slate);
        }


        .formBox {
          display: flex;
          flex-direction: column;
          width: 100%;
          position: relative;
          margin-top: 66px;
        }

        .sendBtn{
          margin-right: 0px;
          background-color: var(--deep-sky-blue);
          padding: initial;
          color: white;
          font-weight: 700;
          width: 20%;
          margin-bottom: 1rem;
          font-size: 0.8rem;
          position: absolute;
          right: 0px;
          width: 118px;
          height: 36px;
          border-radius: 4px;
          border: solid 1px  var(--deep-sky-blue);
        }
        button{
          margin-right: 10px;
          background: gray;
          border-color: gray;
          border-radius: 3px;
          padding: 1rem;
          color: white;
          font-weight: 700;
          width: 20%;
          margin-bottom: 1rem;
          font-size: 0.8rem;

        }

        label{
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--steel);
        }
        .formBody{
          padding-top: 4%;
          width: 50%;
        }
        @media only screen and (min-width: 768px) {
          .forms {
            margin-left: 60px;
            margin-right: 30px;
          }

          .formBody{
            padding-top: 4%;
            width: 60%;
          }
        }
      `}</style>
    </React.Fragment>
  );
}
export default LoginForm























