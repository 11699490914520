import React from 'react';
import { useTranslation,withTranslation } from 'react-i18next';
import {Table} from 'react-bootstrap'
import { getPrescriptions } from '../services/Api'; 
import gfm from 'remark-gfm'

function PrescriptionRecord(props){
  const {t} = useTranslation()
  const data = props.data

  const ReactMarkdownWithHtml = require('react-markdown/with-html')

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  return (
    <React.Fragment>
       <tr>
      <td>

      {data.drugs.map((drug,index) => 
        <div className='drug' key={index}>
        <div className='title'>
          {drug.name}
        </div>
        <div className='info'>
          <span className='info'>{t('prescriptions.dose')} {drug.dose}</span>
          <span className='info'>{t('prescriptions.frequency')} {drug.frequency}</span>
          <span className='info'>{t('prescriptions.length')} {drug.duration}</span>
        </div>
        <div className='s'>
          <div className='title'>
            {t('prescriptions.instrucctions')}
          </div>
          <ReactMarkdownWithHtml plugins={[gfm]} children={data.instrucctions} allowDangerousHtml />
        </div>

      </div>
      )}
        
        
        
      </td>
      
      <td className='info'>{data.doctor}</td>
      <td className='info'>{formatDate(data.date)}</td>
    </tr>
  <style jsx>{`
    .drug{
      padding-bottom: 14px;
    }
    .info{
      margin: 4px 41px 14px 0px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--dark-blue-grey);
    }
    .title{
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--dark-blue-grey);
    }
  `}</style>
    </React.Fragment>
   
  )
}



class Prescriptions extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      prescriptions: [],
      hasMore: true,
      patient: props.patient_id,
      page: 1, 

    };
    
  }

  componentDidMount() {
    this.getData()   
  }

 async getData(){
  
    if ( this.state && this.state.hasMore){
      try {
        const response = await getPrescriptions(this.state.patient);
        
        if(response.request.status === 200 && response.data.prescriptions){
          
          const elements = response.data.prescriptions;
          
          
          if(Object.entries(elements).length !== 0){
            
            if(this.state.page > 1){
              this.setState({prescriptions: this.state.prescriptions.concat(elements)});
            }
            else{
              this.setState({prescriptions: elements});
              
            }

            
            
          }
          if(response.data.meta){
            if(this.state.page < response.data.meta.total_pages){
              var p = this.state.page + 1
              this.setState({page: p})
              this.setState({hasMore: true})
              
            }
            else{
              this.setState({hasMore: false})
            }
          }
        }
      }catch (e) {
           
        console.warn("ERROR", e);
        
      }
    }
    

  }
  drawRecord(element){
    return (<PrescriptionRecord data={element} key={element.date}/>)
  }
  render() {
    const { t } = this.props;
    return (
      <div>
        <div className='title-th'> {t('prescriptions.title')} </div>
        <Table striped  hover variant="ligth">
          <thead>
            <tr>
              <th className='info-th'> {t('prescriptions.name')}</th>
              <th className='info-th'> {t('prescriptions.prescribed')}</th>
              <th className='info-th'> {t('prescriptions.date_prescribed')}</th>
            </tr>
          </thead>
          <tbody>
            {this.state.prescriptions.map(prescription => this.drawRecord(prescription))}
            
            
          </tbody>
        </Table>
        <style jsx global>{`
          .table-striped tbody tr:nth-of-type(odd) {
            background-color: var(--ice-blue);
          }
          .title-th{
            padding-top:28px;
            padding-bottom: 14px;
          }
          .info-th{
            margin: 15px 2px 16px 1px;
            padding: 12px 47px 11px 21px;
            background-color: var(--ice-blue);

            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: var(--steel);
          }
        
        `}</style>
      </div>
    );
  }
}
export default withTranslation()(Prescriptions);