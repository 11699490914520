import  {getAttachments} from '../services/Api'
import React, { useState, useEffect } from "react";
import Empty from './Empty';
import Loading from './LoadingIndicator'
import attachmentImg from '../assets/images/attachment.svg'
function Attachment(props){
  const data = props.data
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }
  return(
    <div className='record col-3 col-s-12'>
      <a href={data.attachment_file_url} target="_blank" rel="noopener noreferrer">
        <div className='attchment-element'>
          
          <div className='info'>
            <img src={attachmentImg} alt="Logo" className='icon-attchment'/>
            
            <div className='attchment-title' > {data.attachment_file_name} </div>
            <div className='attchment-type' > {data.attachment_content_type} </div>
            <div className='attchment-date'> 
              {formatDate(data.created_at)}
            </div>
          </div>
        </div>  
      </a>
      

      <style jsx="true" >{` 
        .attchment-type{
          font-size: 10px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: var(--steel);
        }
        .record{
          padding-left: 0px;
        }
        .attchment-element{
          border-radius: 4px;
          border: solid 1px var(--light-sky-blue);
          background-color: var(--white);
          display: inline-flex;
          padding: 15px;
          padding-top: 26px;
          padding-down: 30px;
          width: 100%;
        }
        .attchment-element:hover{
          border-radius: 7px;
          border: solid 1px #0176fe;
          background-color: rgba(142, 198, 255, 0.1);
        }
        .attchment-date{
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--steel);
        }
        .attchment-title{
          font-size: 24px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--dark-blue-grey);
        }
        img{
          align-self: center;
        }
        .icon-attchment{
          width: 35px;
          height: 35px;
        }
        .info{
      
          align-items: center;
          text-align: center;
        }
      `}</style>
    </div>
    
  )
    

}

function Attachments(props) {
  const [loading, setLoading] = useState(true);
  const [empty, setEmpty] = useState(true);
  const [attachments, setAttachments] = useState([]);
  const patient = props.patient_id;
  async function  componentDidMount() {

    try {
      const response = await getAttachments(patient);
      if(response.request.status === 200){
        const attachments = response.data.attachments;
        setLoading(false);
        if(attachments.length > 0){
          setAttachments(attachments);
          setEmpty(false)
        }
        
      }
    }catch (e) {

      setLoading(false);
      console.warn("ERROR", e);
      
    }
  }

  useEffect(() => {
    componentDidMount();
    // eslint-disable-next-line
  }, [] );

  return (
    <React.Fragment>
      <div className="attachments-container">
        <Loading working={loading}/>
        <Empty empty={empty}/>
        { attachments.map((item,index) =><Attachment data={item} key={index}></Attachment>)}
      </div>
    </React.Fragment>
  )
}


export default Attachments