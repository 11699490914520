
import React from "react";
import  {
  confirmSchedule,
  cancelSchedule } from "../services/Api"
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Image,  
  Button,
  Modal} from 'react-bootstrap'
import "./ConsultationScheduleCard.scss"

function SchedulePortalCard(props){
  const { t } = useTranslation();
  const patient = props.patientAutocomplete;
  const [show, setShow] = React.useState(false);
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false)
  const [consultationSchedule, setConsultationSchedule] = React.useState(props.data);
  const urlPortal = props.urlPortal;
  var randomColor = getRandomColor()

  const colorStatus = () => {
    return consultationSchedule.status === 'pending_cancellation' ? 'warning' : 'primary';
  }

  const formatDate = (dateString) => {
    const options = { weekday: 'long', year: "numeric", month: "long", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  const formatHour = (dateString) => {
    const options = { hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleTimeString('en-US', options)
  }

  const getInitials = (name, fallback = "?") => {
    if (!name || typeof name !== "string") return fallback;
    return name
      .replace(/\s+/, " ")
      .split(" ") // Repeated spaces results in empty strings
      .slice(0, 2)
      .map((v) => v && v[0].toUpperCase()) // Watch out for empty strings
      .join("");
  };

  const getName = (name, fallback = "") => {
    if (!name || typeof name !== "string") return fallback;
    return name;
  }

  function getRandomColor() {

    const bgColors = ['#7B7BFF', '#f66292', '#ffba24', '#00dd73', '#ff8064'];
  
    var color =  bgColors[Math.floor(Math.random() * 6)];

    return color;
  }
  return(
    <div className='consultation-schedule col-xs-6 col-sm-6 col-lg-6'>
      <div className='consultation-card row'>
        <div className='rectangle' style={{backgroundColor: randomColor}}/>
        <div className='info'>
          <div className="row">
              <div className="col-12 cols-info">
                <div className="row">                
                  <div className='doc-info col-12' >
                    <div>
                      <span className='doc-name'>{ getName(consultationSchedule.name) }</span>
                      <Button onClick={() => window.open(urlPortal+'?cellphone='+patient.phone+'&day='+patient.day+'&month='+patient.month+'&year='+patient.year,'_blank')}>{t('appointments.schedule-appointment')}</Button>
                    
                    </div>
                  </div>
                </div>
              </div>
          
         
          </div>
        </div>  
      </div>
   

      <style jsx='true' >{` 
        .org-name{
          display: block;
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--steel);
          margin-top: 7px;
          margin-bottom: 7px;
          
        }
        .doc-name{
          display: block;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--dark-blue-grey);
          margin-top: 7px;
          margin-bottom: 7px;
        }
      `}</style>
    </div>
  )
}
export default SchedulePortalCard;