import React, { useState }  from 'react';
import { useTranslation } from 'react-i18next';

function MedicalHistoryAllergies(props) {
  const others = props.others
// TODO revisar cuando allergy viene null y cuando tiene alergias a medicamentos
  const { t } = useTranslation();
  const [display, setDisplay] = React.useState("block");

  function handleTitle() {
  
    ((display === 'none') ? setDisplay('block') : setDisplay('none'));
  }

  function otherAllergies(){
    if(others !== undefined && others !== null){
      return ( 
        <React.Fragment>
          <span className='type'>{t('medical-history.other-allergies')}</span>
          <ul>
            <li>
              <span className='allergy'>{others}</span>
            </li>
          </ul>
          <style jsx='true'>{
            `
            .type{
              font-size: 12px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: var(--red-pink);
            }
            .allergy{
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.1px;
              color: var(--red-pink);
            }
    
            

            li::before {
              content: '-' !important;
              color: var(--red-pink);
              margin-right: 3px;
            }
            ul{
              list-style-type: none;
            }
            `
          }

          </style>
        </React.Fragment>)
    }
  }
  return (
    <div className='allergy-content'>
      <h1 className='title' onClick={handleTitle.bind()}>{t('medical-history.allergies')}</h1>
      <div className='allergies' id='allergies'>
        <div className='content' id='content'>
          {otherAllergies()}
        </div>
        
        </div>
      <style jsx='true'>{` 
        .allergy-content .allergies{
          border-radius: 4px;
          background-color: rgba(247, 43, 87, 0.11);
          padding: 10px;
          display: ${display};
        }

        .title{
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--deep-sky-blue);
        }
        .title::before {
          content: '+' !important;
          border-radius: 1px;
          width: 10px;
          height: 10px;
          margin-right: 10px
          display: inline-block;
          line-height: 2rem;
          background-color: var(--deep-sky-blue);
          text-align: center;
          padding-left: 5px;
          padding-right: 5px;
          padding-top: 2px;
          padding-bottom: 2px;
          margin-right: 10px;
          color: white !important;
          font-size: 11px;
        }

      `}
      </style>
      
    </div>
  );
}
export default MedicalHistoryAllergies;