import React, { useEffect, useState } from 'react';
import Bus from '../Utils/Bus';

import './Flash.css';

export const Flash = () => {
    let [visibility, setVisibility] = useState(false);
    let [message, setMessage] = useState('');
    let [type, setType] = useState('');

    useEffect(() => {
        Bus.addListener('flash', ({message, type}) => {
            setVisibility(true);
            setMessage(message);
            setType(type);
            setTimeout(() => {
                setVisibility(false);
            }, 4000);
        });
                

    }, []);

    useEffect(() => {
        if(document.querySelector('.close-flash') !== null) {
            document
            .querySelector('.close-flash')
            .addEventListener('click', () => setVisibility(false));
        }
    })

    return (
      visibility && <div className={`alert-flash alert-flash-${type}`}>
          <span className="close-flash"><strong>X</strong></span>
          <p>{message}</p>
      </div>
    )
}
export default Flash;