import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap'
import {
  getSchedules,
  getConsultations,
  getConsultationRequests,
  getOrganizationPortals,
  getOrganizationData
} from "../services/Api";
import ConsultationScheduleCard from '../components/ConsultationScheduleCard';
import PhysicianCard from '../components/PhysicianCard';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { arrayUnique } from '../Utils/ArrayHelper';
import SchedulePortalCard from '../components/SchedulePortalCard';
import ENV from '../environment';


function Appointments(props) {
  const patient = props.location.state.patient;
  const patientId = patient.id;
  const birthday = patient && patient.born_at && patient.born_at.split('-');
  const dominio = ENV.dominionsites;
  const patientAutocomplete = patient.telephone2 && birthday ?
    { phone: patient.telephone2, year: birthday[0], month: birthday[1], day: birthday[2] } : {};
  const { t } = useTranslation();

  const [schedules, setSchedules] = useState([]);
  const [physicians, setPhysicians] = useState([]);
  const [requests, setRequests] = useState([]);
  const [organizationPortals, setOrganizationPortals] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [slug, setSlug] = useState('');
  const [showPhysicianDirectory, setShowPhysicianDirectory] = useState(false);

  useEffect(() => {
    componentDidMount();
    // eslint-disable-next-line
  }, []);


  async function componentDidMount() {
    await callConsultationSchedules()
    await callConsultationRequests()
    await callOrganizationPortals();
    await callMyPhysicians();

    const orgData = await getOrganizationData(patient.id);
    const slug = orgData?.data?.public_organization?.slug ?? null;
    const showPhysicianDirectory = orgData?.data?.public_organization?.landing_page_info?.configuration?.show_physician_directory ?? null;
    setSlug(slug);
    setShowPhysicianDirectory(showPhysicianDirectory);
  }



  async function callConsultationSchedules() {
    if (hasMore) {
      try {
        const response = await getSchedules(patientId, page);
        if (response.request.status === 200 && response.data.consultation_schedules) {

          const elements = response.data.consultation_schedules;

          if (Object.entries(elements).length !== 0) {

            if (page > 1) {
              setSchedules(schedules.concat(elements));
            }
            else {
              setSchedules(elements)
            }
          }
          if (page < response.data.meta.total_pages) {
            var p = page + 1;
            setPage(p);
            setHasMore(true)
          }
          else {
            setHasMore(false);
          }
        }
      } catch (e) {
        console.warn("Failed to get scheduled appointments", e);
      }
    }
  }

  async function callConsultationRequests() {
    try {
      const response = await getConsultationRequests(patientId, page);
      if (response.request.status === 200 && response.data.consultation_requests) {
        const elements = response.data.consultation_requests;
        if (Object.entries(elements).length !== 0) {
          setRequests(elements);

        }
      }
    } catch (e) {

      console.warn("Failed to get appointment requests", e);

    }
  }

  async function callOrganizationPortals() {
    try {
      const response = await getOrganizationPortals(patientId);
      if (response.request.status === 200 && response.data.organization_portals) {
        const elements = response.data.organization_portals;
        let op = [];
        if (Object.entries(elements).length !== 0) {
          op = elements.filter(op => op.configuration && op.configuration.show_in_web_app_patient);
          setOrganizationPortals(op);
        }


      }
    } catch (e) {
      console.warn("Error getting organizational portals", e);
    }
  }

  async function callMyPhysicians() {
    const response = await getConsultations(patientId, page);
    const pages = response && response.data && response.data.meta && response.data.meta.total_pages;
    const consultations = response && response.data && response.data.consultations ? response.data.consultations : [];
    let myPhysicians = [];
    if (pages === 1) {
      myPhysicians = arrayUnique(consultations, 'doctor_name');
      setPhysicians(myPhysicians);
    } else if (pages > 1) {

      let page = 1;
      for (let i = 0; i < pages; i++) {
        let res = await getConsultations(patientId, page);
        let additional_consultations = res && res.data && res.data.consultations
          ? res.data.consultations : [];
        myPhysicians = myPhysicians.concat(additional_consultations);
        page++;
      }
      myPhysicians = arrayUnique(myPhysicians, 'doctor_name');
      setPhysicians(myPhysicians);

    }
  }



  function drawRecord(prefix = '', element) {
    return (
      <ConsultationScheduleCard data={element} key={prefix + element.id} className={prefix + element.id}>
      </ConsultationScheduleCard>
    )
  }

  function drawPhysician(prefix = '', element) {
    return (
      <PhysicianCard patientAutocomplete={patientAutocomplete} data={element} key={prefix + element.id} urlPortal={element.url_portal} className={prefix + element.id}>
      </PhysicianCard>
    )
  }

  function drawPortal(prefix = '', element) {
    return (
      <SchedulePortalCard patientAutocomplete={patientAutocomplete} data={element} key={prefix + element.id} urlPortal={element.url} className={prefix + element.id}>
      </SchedulePortalCard>
    )
  }

  function redirectToDirectory() {
    window.open(`http://${slug}.${dominio}`, '_blank');
  }

  return (
    <div className='content'>
      <Card body className="consultation-card">
        <div className='row'>
          <div className="col-12">
            {(schedules.length > 0 || requests.length > 0) &&
              <>
                <h3 style={{ fontWeight: 'bold', color: '#122744' }}>{t('appointments.upcoming-appointments')}</h3>
                <div className='col-12 row-schedule'>
                  <InfiniteScroll
                    dataLength={schedules.length}
                    next={() => callConsultationSchedules()}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}>
                    {schedules.map((i, index) => (
                      drawRecord('cs', i)
                    ))}
                  </InfiniteScroll>
                  <InfiniteScroll
                    dataLength={schedules.length}
                    next={() => callConsultationRequests()}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}>
                    {requests.map((i, index) => (
                      drawRecord('cr', i)
                    ))}
                  </InfiniteScroll>
                </div>
              </>
            }

            <h3 style={{ fontWeight: 'bold', color: '#122744' }}>{t('appointments.schedule-appointment')}</h3>
            <div className='col-12 row-schedule'>
              <div className="col-8">
                <p style={{ fontWeight: 'bold', color: '#0176fe' }}>{t('appointments.my-physicians')}</p>
              </div>
              <div className="col-4">
                {showPhysicianDirectory &&
                  <Button variant="secondary" disabled={slug === null} onClick={() => redirectToDirectory()}>
                    {patient.organization_name + ' ' + t('appointments.physicians-directory')}
                  </Button>}
              </div>
              {physicians.map((i, index) => (
                drawPhysician('cs', i)
              ))}
            </div>





            <div className='col-12 row-schedule'>
              <p style={{ fontWeight: 'bold', color: '#0176fe' }}>{t('appointments.clinic-schedule')}</p>

              {organizationPortals.map((i, index) => (
                drawPortal('portal', i)
              ))}

            </div>

          </div>



        </div>
      </Card>
      <style jsx="true" >{` 
          .content {
            padding-left: 20px;
            padding-top: 15px;
            max-width: 98%;
          }
          .row-schedule {
            padding-top: 0px;
            padding-bottom: 0px;
          }
        `}</style>
    </div>
  );

}
export default Appointments