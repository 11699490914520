import React from 'react';
import ENV from '../environment';
function Chat(props){
  const patient = props.location.state.patient;
  const token = localStorage.getItem("token");
  var url = `${ENV.nchat}/chatroom?user_id=${patient.id}&token=${token}&type=patient`;
  return (
    <React.Fragment>
      <iframe title='chat' className= 'chat-container' src={url} />
      <style jsx="true" >{`
        .chat-container {
          width: 100%;
          height: 91%;
        }
      `}</style>
    </React.Fragment>

  )
}

export default Chat;