

export function arrayUnique(array,attr_unique){
    let a = array.concat();
        for(let i=0; i<a.length; ++i) {
            for(let j=i+1; j<a.length; ++j) {
                if(a[i][attr_unique] === a[j][attr_unique])
                    a.splice(j--, 1);
            }
        }
    
        return a;
    }