import React, { useState } from "react";
import  {getCountries} from "../services/Api"
import Flag from 'react-world-flags'
import Select from 'react-select-search'
import useLocalStorage from './../hook/useLocalStorage';
import { useTranslation } from 'react-i18next';


function CountryCodeSelect(props) {

  var options = [ ];
  const [countries, setCountries] = useState([]);
  const [flag, setFlag] = useLocalStorage("FLAG", "MX");
  const [cacheCountries, setCacheCountries] = useLocalStorage("COUNTRIES", []);
  const { t } = useTranslation();
  async function  componentDidMount() {
    if(cacheCountries.length > 0){

      setCountries(cacheCountries)
    }
    else{
      try {
        const response = await getCountries();
        await response.forEach(country => {        
          options.push( { value: country.id + '-' + country.country_code, country_code: country.country_code, name: country.name_en, flag: country.abbrv});
        });
  
        
      }catch (e) {
        console.warn("ERROR", e);
      
        
      }

      setCacheCountries(options);
      setCountries(options);
    }


  }

  function renderVal(props, option, snapshot, className) {

    if (option.value){
      setFlag(option.value.flag);
      return (
        <div className="select-search__input"> <Flag code= {option.value.flag}  width="32" height="32"  className='flag'/><input {...props} className='country-name'/></div>
      )
  
    }
    else{
      return ( 
        <div className="select-search__input"> <Flag code= {flag} width="32" height="32"  className='flag'/><input {...props} className='country-name'/></div>
    )
    }
  
  
  }

 
  React.useEffect(()=> {
    componentDidMount();
    // eslint-disable-next-line
  }, []);

  function renderCountry(props, option, snapshot, className) {
    const imgStyle = {
        verticalAlign: 'middle',
        marginRight: 10,
    };

    return (
        <button {...props} className={className} type="button">
            <span><Flag code= {option.flag} style={imgStyle} width="32" height="32" /><span>{option.name}</span></span>
        </button>
    );
}
  return (
    <div className='codeBox'>
      
      <Select
        className="select-search"
        options={countries}
        renderOption={renderCountry}
        renderValue={renderVal}
        search
        placeholder={t('login-form.country-code-placeholder')}
        onChange={props.onUpdateCountry}
    />
    <style jsx global>{`
      .flag{
        width: 22px;
        height: 12px;
        margin-right: 4px;
      }
      .codeBox .select-search {
        margin-top: 14px;
        margin-bottom: 14px;
        position: relative;
        font-family: 'Nunito Sans', sans-serif;
        box-sizing: border-box;
      }
  
      .select-search *,
      .select-search *::after,
      .select-search *::before {
          box-sizing: inherit;
      }

      .codeBox input:focus{
        box-shadow: 0 0.0625rem 0.125rem white;
        
      }
   
      .select-search__value::after {
          content: '';
          display: inline-block;
          position: absolute;
          top: calc(50% - 9px);
          right: 19px;
          width: 11px;
          height: 11px;
      }

      /**
         * Input
         */
      .select-search__input {
          display: block;
          height: 36px;
          width: 100%;
          padding: 0 16px;
          background: #fff;
          border: 1px solid transparent;
          box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
          border-radius: 3px;
          outline: none;
          font-family: 'Noto Sans', sans-serif;
          font-size: 14px;
          text-align: left;
          text-overflow: ellipsis;
          line-height: 36px;
          -webkit-appearance: none;
      }

      .select-search__input::-webkit-search-decoration,
      .select-search__input::-webkit-search-cancel-button,
      .select-search__input::-webkit-search-results-button,
      .select-search__input::-webkit-search-results-decoration {
          -webkit-appearance:none;
      }

      .select-search__input:not([readonly]):focus {
          cursor: initial;
      }

      /**
         * Options wrapper
         */
      .select-search__select {
          
          background: #fff;
          box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
      }

      /**
         * Options
         */
      .select-search__options {
          list-style: none;
      }

      /**
         * Option row
         */
      .select-search__row:not(:first-child) {
          border-top: 1px solid #eee;
      }

      /**
       * Option
       */
      .select-search__option {
          display: block;
          height: 36px;
          width: 100%;
          padding: 0 0px;
          background: #fff;
          border: none;
          outline: none;
          font-family: 'Noto Sans', sans-serif;
          font-size: 14px;
          text-align: left;
          cursor: pointer;
      }

      .select-search--multiple .select-search__option {
          height: 48px;
      }

      .select-search__option.is-selected {
          background:  var(--deep-sky-blue);
          color: #fff;
      }

      .select-search__option.is-highlighted,
      .select-search__option:not(.is-selected):hover {
          background: rgb(96,172,247);
      }

      .select-search__option.is-highlighted.is-selected,
      .select-search__option.is-selected:hover {
          background: rgb(96,172,247);
          color: #fff;
      }

      /**
         * Group
         */
      .select-search__group-header {
          font-size: 10px;
          text-transform: uppercase;
          background: #eee;
          
      }

      /**
         * States
         */
      .select-search.is-disabled {
          opacity: 0.5;
      }

      .select-search.is-loading .select-search__value::after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
          background-size: 11px;
      }

      .select-search:not(.is-disabled) .select-search__input {
          cursor: pointer;
      }

      /**
         * Modifiers
         */
      .select-search--multiple {
          border-radius: 3px;
          overflow: hidden;
      }

      .select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
          transform: rotate(45deg);
          border-right: 1px solid #000;
          border-bottom: 1px solid #000;
          pointer-events: none;
      }

      .select-search--multiple .select-search__input {
          cursor: initial;
      }

      .select-search--multiple .select-search__input {
          border-radius: 3px 3px 0 0;
      }

      .select-search--multiple:not(.select-search--search) .select-search__input {
          cursor: default;
      }

      .select-search:not(.select-search--multiple) .select-search__input:hover {
          border-color:  var(--deep-sky-blue);
      }


      .select-search:not(.select-search--multiple) .select-search__select {
          position: absolute;
          z-index: 2;
          top: 44px;
          right: 0;
          left: 0;
          border-radius: 3px;
          overflow: auto;
          max-height: 360px;
      }

      .select-search--multiple .select-search__select {
          position: relative;
          overflow: auto;
          max-height: 260px;
          border-top: 1px solid #eee;
          border-radius: 0 0 3px 3px;
      }

      .country-name{
          border: solid 1px white !important; 
          width: 90%;
          border-color:  white;
          box-shadow: 0 .0625rem .125rem white;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--slate);
          height: 30px;
      }

  `}
    </style>
    </div>
  );
}
export default CountryCodeSelect























