import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckOption from './CheckOption';
import RadioOption from './RadioOption'
function Vaccines(props) {
  const { t } = useTranslation();
  const months = ['newborn','month_2','month_4','month_6','month_7','month_12','month_18','month_24','month_36','month_48','month_60','month_72','month_120','other']
  const name = props.name;
  const mh = props.mh;  
  const updateMedicalHistory = props.updateMedicalHistory;
  function renderVaccinesOption(vaccine) {
    switch(vaccine) {
      case 'month_2':
        return  ["penta", "hep_b", "rotavirus", "neumo"]
      case 'month_4':
        return  ["penta", "rotavirus", "neumo"]
      case 'month_6':
        return  ["penta", "hep_b", "rotavirus", "flu"]
      case 'month_7':
        return  ["flu"]
      case 'month_12':
        return  ["srp", "neumo"]
      case 'month_18':
        return  ["penta"]
      case 'month_24':
        return  ["flu"]
      case 'month_36':
        return  ["flu"]
      case 'month_48':
        return  ["dpt", "flu"]
      case 'month_60':
        return  ["flu", "vop"]
      case 'month_72':
        return  ["srp"]
      case 'month_120':
        return  ["vph"]
      case 'newborn':
        return  ["bcg", "hep_b"]
      default:
        return 'foo';
    }
  }

 
  function renderOptions(item){
    if(item !== 'other'){
      return(
        <React.Fragment>
          <CheckOption options={renderVaccinesOption(item)} mh={mh} vaccine={name} value={item} title={t('medical-history.' + name + '.' + item + '.name')} updateMedicalHistory={updateMedicalHistory}></CheckOption>
        </React.Fragment>
      )

    }
    else{
      return(
        <React.Fragment>
          <RadioOption name={name} item={item} updateMedicalHistory={updateMedicalHistory} mh={mh}></RadioOption>
        </React.Fragment>
      )
    }

  }
  var m = new Object()
  months.forEach(element =>   m[element] = mh[element]);
  
  return (
    <React.Fragment>
      
      { Object.keys(m).map((item) => 
        {
          return (
            <div key={item}>             
              {renderOptions(item)}
            </div>
          )
        })
      }
    </React.Fragment>
     
  );
}

export default Vaccines;