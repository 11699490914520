
import React from "react";
import  {
  confirmSchedule,
  cancelSchedule } from "../services/Api"
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Image,  
  Button,
  Modal} from 'react-bootstrap'
import "./ConsultationScheduleCard.scss"

function ConsultationScheduleCard(props){
  const { i18n, t } = useTranslation();
  const [show, setShow] = React.useState(false);
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false)
  const [consultationSchedule, setConsultationSchedule] = React.useState(props.data);
  var randomColor = getRandomColor()

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseCancel = () => setShowCancelModal(false);
  const handleShowCancel = () => setShowCancelModal(true);

  const confirm = () => {
    setLoading(true);
    confirmSchedule(consultationSchedule.id).then((response) => {
      if(response.data && response.data.consultation_schedule) {
        setConsultationSchedule(response.data.consultation_schedule);
        window.flash(t('appointments.actions.confirm.success'), 'success')
      } else {
        window.flash(t('appointments.actions.confirm.error'), 'error')
      }
      setLoading(false);
    });
    handleClose();
  }

  const cancel = () => {
    setLoading(true);
    cancelSchedule(consultationSchedule.id).then((response) => {
      if(response.data && response.data.consultation_schedule) {
        setConsultationSchedule(response.data.consultation_schedule);
        window.flash(t('appointments.actions.cancel.success'), 'success')
      } else {
        window.flash(t('appointments.actions.cancel.error'), 'error')
      }
      setLoading(false);
    });
    handleCloseCancel();
  }

  const colorStatus = () => {
    return consultationSchedule.status === 'pending_cancellation' ? 'warning' : 'primary';
  }

  const formatDate = (dateString) => {
    const options = { weekday: 'long', year: "numeric", month: "long", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  const formatHour = (dateString) => {
    const options = { hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleTimeString('en-US', options)
  }

  const getInitials = (name, fallback = "?") => {
    if (!name || typeof name !== "string") return fallback;
    return name
      .replace(/\s+/, " ")
      .split(" ") // Repeated spaces results in empty strings
      .slice(0, 2)
      .map((v) => v && v[0].toUpperCase()) // Watch out for empty strings
      .join("");
  };

  function getRandomColor() {

    const bgColors = ['#7B7BFF', '#f66292', '#ffba24', '#00dd73', '#ff8064'];
  
    var color =  bgColors[Math.floor(Math.random() * 6)];

    return color;
  }

  const getDoctorSpeciality = (model) => {
    try {
      let keyName = `doctor_speciality_${i18n.language}`;

      if (model.hasOwnProperty(keyName)) {
        return model[keyName];
      } else {
        return model.doctor_speciality;
      }
    } catch (error) {
      return model.doctor_speciality;
    }
  };

  return(
    <div className='consultation-schedule col-xs-12 col-sm-12 col-lg-12'>
      <div className='consultation-card row'>
        <div className='rectangle' style={{backgroundColor: randomColor}}/>
        <div className='info'>
          <div className="row">
              <div className="col-6 cols-info">
                <div className="row">                
                  <div className="col-4 row-avatar">
                    {consultationSchedule.doctor_image
                        ? <Image className="doc-avatar" src={consultationSchedule.doctor_image} roundedCircle />
                        : <div className='initials' style={{backgroundColor: randomColor}}>{getInitials(consultationSchedule.doctor_name)}</div> 
                    }
                  </div>
                  <div className='doc-info col-8' >
                    <div>
                      <span className='doc-name'>  {consultationSchedule.doctor_name}</span>
                      <span className='doc-name'>  {getDoctorSpeciality(consultationSchedule)}</span>
                      <span className='org-name'>{consultationSchedule.organization_name}</span>
                      <Badge className="mb-1 mr-1" variant="secondary">
                        {t('appointments.' + consultationSchedule.schedule_type )}
                      </Badge>
                      <Badge className="mb-1 mr-1" variant={colorStatus()}>
                        {t('appointments.status.' + consultationSchedule.status )}
                      </Badge>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-2 cols-info">
                <div className='consultation-info'> 
                  <span className='cause'>{consultationSchedule.cause}  </span>
                </div>
              </div>
              <div className="col-2 cols-info">
                <div className='date'>
                  <span >{formatDate(consultationSchedule.starts_at)} </span>
                <br></br>
                  <span className='hour'>
                    {formatHour(consultationSchedule.starts_at)} { consultationSchedule.ends_at && "-"} { consultationSchedule.ends_at && formatHour(consultationSchedule.ends_at)}
                  </span>
                </div>
              </div>
              <div className="col-2 cols-info">
                <div className='consultation-info'> 
                  { (consultationSchedule.status === 'scheduled' || consultationSchedule.status === 'cancellation_request_rejected') && consultationSchedule.can_confirm_schedule &&
                    <Button disabled={isLoading} variant="primary" key={"confirm" + consultationSchedule.id} className="w-100 mb-1 confirm-action" onClick={handleShow}>
                      {t('appointments.actions.confirm.title')}
                    </Button>
                  }
                  <br></br>
                  
                  { consultationSchedule.status !== 'pending_cancelattion' && consultationSchedule.can_cancel_schedule &&
                    <Button disabled={isLoading} variant='secondary' key={"cancel" + consultationSchedule.id} className="w-100 cancel-action" onClick={handleShowCancel}>
                      {t('appointments.actions.cancel.title')}
                    </Button>
                  }
                </div>
              </div>
          </div>
        </div>  
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('appointments.actions.confirm.modal.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('appointments.actions.confirm.modal.message')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('appointments.actions.close')}
          </Button>
          <Button variant="primary" onClick={confirm.bind()}>{t('appointments.actions.confirm.title')}</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showCancelModal}
        onHide={handleCloseCancel}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('appointments.actions.cancel.modal.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('appointments.actions.cancel.modal.message')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCancel}>
            {t('appointments.actions.close')}
          </Button>
          <Button variant="primary" onClick={cancel.bind()}>{t('appointments.actions.cancel.title')}</Button>
        </Modal.Footer>
      </Modal>

      <style jsx='true' >{` 
        .org-name{
          display: block;
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--steel);
          margin-top: 7px;
          margin-bottom: 7px;
          
        }
        .doc-name{
          display: block;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--dark-blue-grey);
          margin-top: 7px;
          margin-bottom: 7px;
        }
      `}</style>
    </div>
  )
}
export default ConsultationScheduleCard;