import axios from "axios";
import ENV from '../environment';

const API_URL = ENV.apiUrl
const PATIENT_SCOPE = ENV.patientScope;
const API = (function () {

  const POST = async (endpoint, formData) => {
    try {
      const response = await axios.post(`${PATIENT_SCOPE}${endpoint}`, formData);
      
      return response;
    }catch (e) {
      console.warn("POST", endpoint, e);
      return e;
      
    }
  }

  const PATCH = async (endpoint, formData) => {
    try {
      const response = await axios.patch(`${PATIENT_SCOPE}${endpoint}`, formData, {
         headers: { Authorization: localStorage.getItem('token') }
      });

      return response;
    }catch (e) {

      console.warn("PATCH", endpoint, e);
      return e;
      
    }
  }

  const GET = async (url, params) => {
    try {
      const response = await axios.get(url, { headers: { Authorization: localStorage.getItem('token') } });
      return response;

    }catch (e) {
      console.warn("GET", url, e);
         if (e.response.status  === 401){
        localStorage.removeItem('countryCode');
        localStorage.removeItem('verificationCode');
        localStorage.removeItem('phone');
        localStorage.removeItem('token');
        window.location.href = "/";
      }
      return e;
      
    }
  }
  return {
    GET,
    POST, 
    PATCH
  }

})();


async function getRequestCode(formData){
	try {
    let endpoint = `/auth`
    const data = await API.POST( endpoint, formData);
    return data;
	}catch (e){
  	console.warn('some error', e);
  }
 
}
async function codeValidation(formData){
	try {
    let endpoint = `/validate`
    const data = await API.POST( endpoint, formData);
    return data;
	}catch (e){
  	console.warn('some error', e);
  }
 
}

async function getPatientsList(){
	try {
    let endpoint = `${PATIENT_SCOPE}/people`
    const data = await API.GET(endpoint);
    return data;
	}catch (e){
  	console.warn('some error', e);
  }
 
}
async function getSchedules(patient_id, page){
	try {
    let endpoint = `${PATIENT_SCOPE}/people/${patient_id}/consultation_schedules?page=${page}`
    const data = await API.GET(endpoint);
    return data;
	}catch (e){
  	console.warn('some error', e);
  }
}

async function getOrganizationData(patient_id){
	try {
    let endpoint = `${PATIENT_SCOPE}/people/${patient_id}/organization`
    const data = await API.GET(endpoint);
    return data;
	}catch (e){
  	console.warn('some error', e);
  }
}

async function getConsultationRequests(patient_id, page){
	try {
    let endpoint = `${PATIENT_SCOPE}/people/${patient_id}/consultation_requests?page=${page}`
    const data = await API.GET(endpoint);
    return data;
	}catch (e){
  	console.warn('some error', e);
  }
}

async function getOrganizationPortals(patient_id){
	try {
    let endpoint = `${PATIENT_SCOPE}/people/${patient_id}/organization_portals`
    const data = await API.GET(endpoint);
    return data;
	}catch (e){
  	console.warn('some error', e);
  }
}

async function confirmSchedule(consultation_schedule){
	try {
    let endpoint = `/consultation_schedule/${consultation_schedule}/confirm_schedule`
    let auth = { headers: { Authorization: localStorage.getItem('token') } };
    const data = await API.POST(endpoint, auth);
    return data;
	}catch (e){
  	console.warn('confirm schedule', e);
  }
}

async function cancelSchedule(consultation_schedule){
	try {
    let endpoint = `/consultation_schedule/${consultation_schedule}/cancel_schedule`
    let auth = { headers: { Authorization: localStorage.getItem('token') } };
    const data = await API.POST(endpoint, auth);
    return data;
	}catch (e){
  	console.warn('cancel schedule', e);
  }
}

async function getCountries(){
	try {
    let url = `${API_URL}/countries`
    const data = await API.GET(url);
    return data.data.countries;
	}catch (e){
  	console.warn('some error', e);
  }
 
}
async function getMedicalHistory(patient_id){
  try {
    let endpoint = `${PATIENT_SCOPE}/people/${patient_id}/medical_history`
    const response = await API.GET(endpoint)
    if(response.request.status === 200 && Object.keys(response.data).length > 0 && response.data.medical_history !== null ){
      return response;
    }
    else{
      return [];
    }
  }catch (e){
  	console.warn('some error', e);
  }

}
async function getVitalSigns(patient_id){
	try {
    let endpoint = `${PATIENT_SCOPE}/people/${patient_id}/vital_signs`
    const data = await API.GET(endpoint);
    return data;
	}catch (e){
  	console.warn('some error', e);
  }
 
}

async function getLabTests(patient_id){
	try {
    let endpoint = `${PATIENT_SCOPE}/people/${patient_id}/lab_tests`
    const data = await API.GET(endpoint);
    return data;
	}catch (e){
  	console.warn('some error', e);
  }
 
}

async function getActiveDrugs(patient_id){
	try {
    let endpoint = `${PATIENT_SCOPE}/people/${patient_id}/drugs`
    const data = await API.GET(endpoint);
    return data;
	}catch (e){
  	console.warn('some error', e);
  }
 
}

async function getAttachments(patient_id){
	try {
    let endpoint = `${PATIENT_SCOPE}/people/${patient_id}/attachments`
    const data = await API.GET(endpoint);
    return data;
	}catch (e){
  	console.warn('some error', e);
  }
 
}

async function getConsultations(patient_id, page){
	try {
    let endpoint = `${PATIENT_SCOPE}/people/${patient_id}/consultations?page=${page}`
    const data = await API.GET(endpoint);
    return data;
	}catch (e){
  	console.warn('some error', e);
  }
 
}

async function getPrescriptions(patient_id){
	try {
    let endpoint = `${PATIENT_SCOPE}/people/${patient_id}/prescriptions`
    const data = await API.GET(endpoint);
    return data;
	}catch (e){
  	console.warn('some error', e);
  }
 
}
async function updateMedicalHistory(formData,medical_history_id){
	try {
    let endpoint = `/medical_histories/${medical_history_id}`
    const data = await API.PATCH( endpoint, formData);
    return data;
	}catch (e){
  	console.warn('some error', e);
  }
 
}

export default API;
export {
  getRequestCode,
  codeValidation,
  getPatientsList,
  getCountries,
  getMedicalHistory,
  getVitalSigns,
  getActiveDrugs,
  getLabTests,
  getAttachments,
  getConsultations, 
  getPrescriptions,
  getSchedules,
  getConsultationRequests,
  getOrganizationPortals,
  confirmSchedule,
  cancelSchedule,
  updateMedicalHistory,
  getOrganizationData
};
