import React, { useState, useEffect } from 'react';
import logoImg from "../assets/images/nimbo-logo.png";

import { useTranslation } from 'react-i18next';
import HeartOn from '../assets/images/heart-on.svg';
import CalendarOff from '../assets/images/calendar-off.svg';
import ChatOff from '../assets/images/chat-off.svg';
import { useHistory } from 'react-router-dom';
import  {
  getOrganizationData
} from "../services/Api"

function ProfileSideBar(props) {
  const { t } = useTranslation();
  const [logo, setLogo] = useState('');

  let history = useHistory();
  const patient = props.patient;
  function goProfile(e){
  
    history.push('/profile' , { patient: patient });
  }
  function goAppointmets(e){
  
    history.push('/appointments' , { patient: patient });
  }
  function goChat(e){
    if(patient.rooms){
      history.push('/chat' , { patient: patient });
    }
    else{
      history.push('/appstore' , { patient: patient });
    }
  }

  async function  componentDidMount() {

    try {
      const response = await getOrganizationData(patient.id);
      if(response.request.status === 200){
        setLogo(response.data.public_organization.logo)
      }
    }catch (e) {
      setLogo(logoImg)
      console.warn("ERROR in get organization data", e);
    }
    return true;
  }

  useEffect(() => {
    componentDidMount();
  }, [])

  
  return (
    <div className='profile-bar'>
      {logo.length > 0 && 
        <img src={logo} alt="Logo" className='nimbo-logo'/>
      }
    <ul>
      <li onClick={goProfile.bind()}><span className='custom-li'><img src={HeartOn} alt="Logo" className='icon'/> {t('profile.sidebar.my-health')}</span></li>
      <li onClick={goAppointmets.bind()}><span className='custom-li'><img src={CalendarOff} alt="Logo" className='icon'/> {t('profile.sidebar.appointments')} </span> </li>
      <li onClick={goChat.bind()}><span className='custom-li'><img src={ChatOff} alt="Logo" className='icon'/> {t('profile.sidebar.messages')}</span> </li>
    </ul>
   
     <style jsx>{
       `
       .profile-bar .custom-li{
         dislpay: flex;
         cursor: pointer;
       }
       .profile-bar ul{
        list-style-type: none;
        margin-top: 44px;
        padding-left: 0px;
       }
      .profile-bar li {
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--dark-blue-grey);
        margin-top: 30px;
       }

      .profile-bar .nimbo-logo{
        width: 130px;
        margin-bottom: 65px;
      }
       `
     }
      
     </style>
    </div>
  );
}
export default ProfileSideBar;