function ENV() {
  let ENV = {
    apiUrl: '',
    apiPath: 'patient_portal',
    patientScope: ''
  };

  if(process.env.REACT_APP_STAGE === 'development') {
    ENV.apiUrl = 'http://localhost:5000/api/v1';
    ENV.nchat = 'http://localhost:3000';
    ENV.dominionsites = 'nimbosites.pw';
  }

  if(process.env.REACT_APP_STAGE === 'staging') {
    ENV.apiUrl = 'https://nimbox-api-staging.herokuapp.com/api/v1';
    ENV.nchat = 'https://nchat.ivanq.rocks';
    ENV.dominionsites = 'nimbosites.pw';
  }

  if(process.env.REACT_APP_STAGE === 'production') {
    ENV.apiUrl = 'https://nimbox-api-production.herokuapp.com/api/v1';
    ENV.nchat = 'http://nimbochat.s3-website-us-west-1.amazonaws.com';
    ENV.dominionsites = 'nimbo.io';
  }

  ENV.patientScope = `${ENV.apiUrl}/${ENV.apiPath}`;

  return ENV;
};

export default ENV();