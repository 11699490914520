import React from "react";
import SideBar from "../components/ProfileSideBar";
import TopBar from '../components/TopBarProfile'
const withProfileLayout = Component => props => {

  const patient = props.location.state.patient
  function customComponent(){
      
    return <Component {...props} />;
  }

     
  return (
    <React.Fragment>
      <div className="row"> 
        <div className='sideNav col-2 col-s-12'> <SideBar patient={patient}/> </div>
        <div className= 'container col-10 col-s-12' > 
          <TopBar patient={patient}/>
          {customComponent()}
      </div>
      </div>
      <style jsx>{`
        .sideNav {
          
          z-index: 1;
          top: 0;
          left: 0;
          background-color: white;
          overflow-x: 'hidden';
          padding-top: 20px;
          padding-left: 3.5%;
          box-shadow: 0 2px 4px 0 #c3cbd1;

        }
        .container {
          background-color: #F3F8F9;
          fontSize: 28px;
          padding: 0px;
        }
      `}</style>
  </React.Fragment>
  );
}
  
export default withProfileLayout;