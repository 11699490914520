import React from 'react';
import { useTranslation } from 'react-i18next';
function RadioOption(props) {

  const name = props.name;
  const item = props.item;
  const mh = props.mh;
  const updateMedicalHistory = props.updateMedicalHistory;
    
  const { t } = useTranslation();
  function onChangeActive(item, e) {
    if (mh[item]) {
      let val = { ...mh };

      if (e.target.value == 'Yes') {
        val[item]['active'] = true;
      } else {
        val[item]['active'] = false;
        val[item]['text'] = null;
      }
      
      updateMedicalHistory(val);
    }
  }

  const checkedYes = React.useCallback(
    (item, e) => {
      if (mh[item]) {
        if (
          mh[item].active !== undefined &&
          mh[item].active !== null
        ) {
          if (mh[item].active) {
            return true;
          }
        }
      }
      return false;
    },
    [mh]
  );

  function checkedNo(item, e) {
    
    if (mh[item]) {
      if (
        mh[item].active !== undefined &&
        mh[item].active !== null
      ) {
        if (!mh[item].active) {
          return true;
        }
      }
    }
    return false;
  }


  function renderRecordDescription(item) {
    const handleRecordChange = (event) => {
      let newMedicalHistory = { ...mh };
      newMedicalHistory[item]['text'] = event.target.value;
      
      updateMedicalHistory(newMedicalHistory);
    };
    if (mh[item]) {
      if (mh[item]['active']) {
        return (
          <div
            id={'active-input-' + name + '-' + item}
            className="active-input"
          >
            <textarea
              style={{ width: '50%', margin: '2px' }}
              value={mh[item]['text'] || ''}
              onChange={(e) => handleRecordChange(e)}
            ></textarea>
          </div>
        );
      } else {
        return <></>;
      }
    }
  }
  return (
    <React.Fragment>
      <div className="section-record">
        <div className="title-section">
          {t('medical-history.' + name + '.' + item)}
        </div>

        
        <div className="yes-no-input">
          <input
            type="radio"
            className={'radio-' + name + '-' + item + '-no'}
            id='no-input'
            name={'active-' + name + '-' + item}
            value="No"
            onChange={(e) => onChangeActive(item, e)}
            checked={checkedNo(item)}
          />
          <label htmlFor="no">{t('medical-history.forms.no')}</label>

          <input
            type="radio"
            className="yes-no-input"
            id={'radio-' + name + '-' + item + '-yes'}
            name={'active-' + name + '-' + item}
            value="Yes"
            checked={checkedYes(item)}
            onChange={(e) => onChangeActive(item, e)}
          />
          <label htmlFor="yes">{t('medical-history.forms.yes')}</label>
        </div>
      </div>

      {renderRecordDescription(item)}
      <style jsx='true'>{`
        .section-record {
          display: inline-flex !important;
          width: 100%;
        }
        .yes-no-input {
          width: 20%;
          font-family: "Proxima Nova", Helvetica, Arial, sans-serif;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--dark-blue-grey);
        }
        .active-input {
          width: 100%;
        }
        .title-section{
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--dark-blue-grey);
          max-width: 40%;
          min-width: 40%;
          margin: 0px;
          margin-bottom: 13px;
        }
    
      `}</style>
    </React.Fragment>
  );
}
export default RadioOption;