import React from 'react';

function LoadingIndicator(props) {
  var working = props.working;

  return (
    <React.Fragment>
      {working ? <div className="spinner-border text-primary"></div> : null}
    </React.Fragment>
     
  );
}

export default LoadingIndicator;


