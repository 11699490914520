import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './i18n';
import './App.css';
import Login from  './pages/Login.js'
import CodeValidation from  './pages/CodeValidation.js'
import PatientList from  './pages/PatientList.js'
import Appointments from  './pages/Appointments.js'
import NoUser from  './pages/NoUser.js'
import Profile from './pages/Profile'
import { AuthContext } from "./context/auth";
import PrivateRoute from './decorators/PrivateRoute';
import withLoginLayout from "./hocs/withLoginLayout";
import withProfileLayout from "./hocs/withProfileLayout";
import {isMobile} from 'react-device-detect';
import { useMediaQuery } from 'react-responsive'
import Appstore from './components/Appstore'
import Flash from './components/Flash';
import Bus from './Utils/Bus';

import Chat from './pages/Chat';
function App() {
  const existingToken = localStorage.getItem("token");
  const [authToken, setAuthToken] = useState(existingToken);
  
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1000px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
 

  const setToken = (token) => {
    localStorage.setItem("token", token);
    setAuthToken(token);
  }
  if(isMobile || isTabletOrMobile) {
    return (
      <Appstore></Appstore>
    );
    
  }

  window.flash = (message, type="success") => Bus.emit('flash', ({message, type}));


  return (
    

    <AuthContext.Provider value={{ authToken, setAuthToken: setToken }}>
      <Router>
      <Flash />
      <Switch>
        <Route exact path="/" component={withLoginLayout(Login)} />
         <Route path="/code-validation" component={CodeValidation}/>
         <PrivateRoute path="/patients" component={withLoginLayout(PatientList)}/>
         <PrivateRoute path="/change-patient/" component={withProfileLayout(PatientList)}/>
         {/* <PrivateRoute path="/profile" component={withProfileLayout(Profile)}/> */}
         <PrivateRoute path="/profile" component={Profile}/>
         <PrivateRoute path="/appointments" component={withProfileLayout(Appointments)}/>
         <PrivateRoute path="/appstore" component={withProfileLayout(Appstore)}/>
         <Route path="/no-user" component={NoUser} />
         <Route path="/appstore" component={withProfileLayout(Appstore)} />
         <Route path="/chat" component={withProfileLayout(Chat)} />

      </Switch>
      </Router>
    </AuthContext.Provider>

  //   <div>

  //   {isDesktopOrLaptop && <>
  //     <p>You are a desktop or laptop</p>
  //     {isBigScreen && <p>You also have a huge screen</p>}
  //     {isTabletOrMobile && <p>You are sized like a tablet or mobile phone though</p>}
  //   </>}
  // </div>
  );
}

export default App;

