import React from 'react';
import { useHistory } from 'react-router-dom';

function PatientCard(props) {

  let history = useHistory();
  const patient = props.patient;
  function goProfile(e){
  
    history.push('/profile' , { patient: patient });
   
  }
  const getInitials = (name, fallback = "?") => {
    if (!name || typeof name !== "string") return fallback;
    return name
      .replace(/\s+/, " ")
      .split(" ") // Repeated spaces results in empty strings
      .slice(0, 2)
      .map((v) => v && v[0].toUpperCase()) // Watch out for empty strings
      .join("");
  };

  function getRandomColor() {

    const bgColors = ['#7B7BFF', '#f66292', '#ffba24', '#00dd73', '#ff8064'];
  
    var color =  bgColors[Math.floor(Math.random() * 6)];

    return color;
  }

  return (
    <React.Fragment>
    <div className='patientItem' onClick={goProfile.bind()}>
      <div className='info'>
        <div className='initials' style={{backgroundColor: getRandomColor()}}>{getInitials(patient.full_name)}</div> 
        <div className='body'>
          <div className='name'>{patient.full_name}</div>
          <div className='organization'>
            {patient.organization_name}
         </div>
          <div className='phone'>
            {patient.telephone2}
          </div>
      </div>
        
      </div>
     
  
      <style jsx='true'>{`
        .patientItem {
          padding: 5px;
          padding-top: 15px;
          padding-bottom: 16px;
          margin-top: 0px; 
          margin-bottom: 12px;
          background-color: white; 
          border-width: 2px;
          border-color: rgb(226,236,240);
          border-radius: 4px;
          border-style: solid;
          border: solid 1px var(--light-sky-blue);
        }
        .patientItem:hover {
          border-radius: 4px;
          border: solid 1px var(--deep-sky-blue);
          background-color: rgba(142, 198, 255, 0.1);
        }
        .info {
          display: inline-flex;
          margin-left: 4%;
        }
        .body{ 
          margin-left: 23px;
        }
        .initials{
          border-radius: 50%;
          background-color: #f66292;
          width: 50px;
          height: 50px;
          text-align: center;
          color: white;
          padding: 6% 0;
        }
        .name{
          padding-top: 0px;
          margin-top: 0px;
          font-size: 18px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--dark-blue-grey);

        }
        .organization{
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--steel);
          margin-top: 2px;
        }
        .phone{
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--steel);
          margin-top: 2px;
        }
      `}</style>
    </div>
    </React.Fragment>
  );
}

export default PatientCard;