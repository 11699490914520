import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const locales = ['en', 'es'];
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);


  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
    localStorage.setItem('selectedLanguage', lng);
  };

  useEffect(() => {
    setSelectedLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <div>
      <ul id="" className="locale-switcher">
        {locales.map((locale) => (
          selectedLanguage === locale ? (
            <li key={locale}>{locale.toUpperCase()}</li>
          ) : (
            <li key={locale}>
              <button 
                onClick={() => handleLanguageChange(locale)} 
                style={{ 
                  background: 'none', 
                  color: 'blue', 
                  textDecoration: 'underline', 
                  border: 'none', 
                  padding: 0, 
                  cursor: 'pointer' 
                }}
              >
                {locale.toUpperCase()}
              </button>
            </li>
          )
        ))}
      </ul>
    <style jsx='true'>{`
      .locale-switcher {
        position: absolute;
        top: 15px;
        right: 30px; }
      .locale-switcher li {
        display: inline;
        text-transform: uppercase; }
      .locale-switcher li:after {
        content: '|';
        padding-left: 5px; }
      .locale-switcher li:last-child:after {
        content: ''; }
      .locale-switcher a {
        cursor: pointer;
      }
    `}</style>
    </div>
  );
};

export default LanguageSwitcher;
