import React from 'react';
import { useTranslation } from 'react-i18next';

function Empty(props) {
  var empty = props.empty;
  const { t } = useTranslation();
  
  return (
    <React.Fragment>
      {empty ? <h1> {t('common.no-data')} </h1> : null}
    </React.Fragment>
     
  );
}

export default Empty;


