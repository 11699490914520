import  {getLabTests} from '../services/Api'
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Loading from './LoadingIndicator'
import Empty from '../components/Empty'
function LabTestRecord(props){
  const { t } = useTranslation();

  const { data, value} = props;
  return(
    <div className='record col-3 col-s-12 '>
      <div className='lab-test'>
      
        <div className='info'>
          <div className='lab-title' > {t('lab-test-result.' + data.group +'.elements.' + props.title )} </div>
          <div className='vital-values'> 
            {value} {data.units}
          </div>
        </div>
      </div>  
      <style jsx="true" >{` 
        .record{
          padding-left: 0px;

        }
        .lab-test{
          border-radius: 4px;
          border: solid 1px var(--light-sky-blue);
          background-color: var(--white);
          display: inline-flex;
          padding: 15px;
          padding-top: 26px;
          padding-down: 30px;
          width: 100%;
        }
        .lab-title{
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--steel);
        }
        .vital-values{
          font-size: 24px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--dark-blue-grey);
        }
        img{
          align-self: center;
        }
        .icon-vital{
          width: 50px;
          height: 50px;
        }
        .info{
          padding-left: 16px;
        }
      `}</style>
    </div>
  )
}

function LabTests(props) {

  const [loading, setLoading] = useState(true);
  const [empty, setEmpty] = useState(true);
  const [tests, setTests] = useState([]);
  const patient = props.patient_id;
  const { t } = useTranslation();
  async function  componentDidMount() {

    try {
      const response = await getLabTests(patient);
      
      if(response.request.status === 200 && response.data.lab_tests){
        
        const tests = response.data.lab_tests.elements;
        if(tests){
          if(Object.entries(tests).length !== 0){
            
            setTests(tests);
            setEmpty(false)
          }
        }
        setLoading(false);
        
      }else{
       setEmpty(true)
      }
    }catch (e) {
      
      setLoading(false);
      console.warn("ERROR", e);
      
    }
  }

  useEffect(() => {
    componentDidMount();
    // eslint-disable-next-line
  }, []);
  
  function drawRecord(element){
    
    if(element.value){
      let value = element.value 
      if (element.custom_form) {
        const options = element.custom_form ? element.custom_form.options : [];
        if (options.length > 0) {
          value = t('lab-test-result.units-title.' + element.value )
        }
      }
      return (<LabTestRecord title={element.name} data={element} key={element.name} value={value}></LabTestRecord>)
    }
  }
  return (
    <React.Fragment>
      <div className="tests-list">
        <Loading working={loading}/>
        <Empty empty={empty}/>
        { Object.keys(tests).map((item) => drawRecord(tests[item]))}
      </div>
    </React.Fragment>
  )
}


export default LabTests